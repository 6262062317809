import API from "@aws-amplify/api";
import { Auth } from "aws-amplify";

class APIService {

    static async put(endpointName, path, body) {
        const session = await Auth.currentSession()
        // const userInfo = await Auth.currentUserPoolUser()
        // JSON.stringify(userInfo)
        if (session.getIdToken() != null) {
            return API.put(endpointName, path, {
                headers: {
                    Authorization: session.getIdToken().getJwtToken(),
                },
                body: body
            });
        }
    };

    static async putWithQueryString(endpointName, path, queryStringParameters) {
        const session = await Auth.currentSession()
        // const userInfo = await Auth.currentUserPoolUser()
        // JSON.stringify(userInfo)
        if (session.getIdToken() != null) {
            return API.put(endpointName, path, {
                headers: {
                    Authorization: session.getIdToken().getJwtToken(),
                },
                queryStringParameters: queryStringParameters
            });
        }
    };

    static async post(endpointName, path, body) {
        const session = await Auth.currentSession()
        // const userInfo = await Auth.currentUserPoolUser()
        if (session.getIdToken() != null) {
            return API.post(endpointName, path, {
                headers: {
                    Authorization: session.getIdToken().getJwtToken(),
                },
                body: body
            });
        }
    };

    static async postWithQueryString(endpointName, path, queryStringParameters) {
        const session = await Auth.currentSession()
        // const userInfo = await Auth.currentUserPoolUser()
        if (session.getIdToken() != null) {
            return API.post(endpointName, path, {
                headers: {
                    Authorization: session.getIdToken().getJwtToken(),
                },
                queryStringParameters: queryStringParameters
            });
        }
    };

    static async delete(endpointName, path, body) {
        const session = await Auth.currentSession()
        if (session.getIdToken() != null) {
            return API.del(endpointName, path, {
                headers: {
                    Authorization: session.getIdToken().getJwtToken(),
                },
                body: body
            });
        }
    };

    static async deleteWithQueryString(endpointName, path, queryStringParameters) {
        const session = await Auth.currentSession()
        if (session.getIdToken() != null) {
            return API.del(endpointName, path, {
                headers: {
                    Authorization: session.getIdToken().getJwtToken(),
                },
                queryStringParameters: queryStringParameters
            });
        }
    };

    static async get(endpointName, path, queryStringParameters) {
        const session = await Auth.currentSession()
        // console.log(session)
        // const userInfo = await Auth.currentUserPoolUser()
        // JSON.stringify(userInfo)
        // const userAlias = userInfo['username'].split('_')[1]

        if (session.getIdToken() != null) {
            return API.get(endpointName, path, {
                headers: {
                    Authorization: session.getIdToken().getJwtToken(),
                    // "X-userAlias": userAlias
                },
                queryStringParameters: queryStringParameters
            });
        }
    };
}

export default APIService;
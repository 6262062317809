import { useState, useEffect } from 'react';

import Container from "@awsui/components-react/container";
import Header from "@awsui/components-react/header";
import SpaceBetween from "@awsui/components-react/space-between";
import Grid from "@awsui/components-react/grid";
import Table from "@awsui/components-react/table";
import Link from "@awsui/components-react/link";
import APIService from "./service/APIService";
import ColumnLayout from "@awsui/components-react/column-layout";
import Box from "@awsui/components-react/box";
import Alert from "@awsui/components-react/alert";
import MDEditor from "@uiw/react-md-editor";

export default function Main() {
    const [mainClub, setMainClub] = useState({});
    const [numOfMainClubMembers, setNumOfMainClubMembers] = useState();
    const [numOfReimburseMembers, setNumOfReimburseMembers] = useState();
    const [clubStatusCode, setClubStatusCode] = useState([])

    const [operatorItems, setOperatorItems] = useState([]);
    const [memberItems, setMemberItems] = useState([]);
    const [alertType, setAlertType] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [alertVisible, setAlertVisible] = useState(false)
    const [clubInfoVisible, setClubInfoVisible] = useState(false)
    const [clubInfoMessage, setClubInfoMessage] = useState('')
    const [loading, setLoading] = useState(false)
    // const [reImburseClubTitle, setReImburseClubTitle] = useState('비용지원 동호회 상세 정보')

    useEffect(() => {
        getClubStatusCodeList()
        retrieveMyClubs()
    }, [])

    const getClubStatusCodeList = () => {
        APIService.get('kcms', '/clubs/statuslist', {})
            .then(res => {
                setClubStatusCode(res);
            })
            .catch(err => {
            })
    }

    const retrieveMyClubs = () => {
        setLoading(true)
        APIService.get('kcms', '/clubs', { joined: true }).then(res => {
            console.log("result OK : " + JSON.stringify(res));
            // 비용 지원 동호회가 없을 경우 비용 지원 동호회가 없는 상태임을 강조해서 알려주기...
            if(res.member === null) {
                setClubInfoVisible(true)
                setClubInfoMessage('가입된 동호회가 없습니다. 동호회 리스트에서 검색 후 동호회를 가입할 수 있습니다.')
            } else if (res.member.reimburseClubId === null) {
                setClubInfoVisible(true)
                setClubInfoMessage('비용지원 동호회가 지정되어 있지 않습니다. 내 동호회 리스트에서 비용지원 동호회를 설정할 수 있습니다.')
            }
            //Check reimburseInfo 비용지원 동호회 정보가 있는 경우에만 데이터를 가져오도록 변경
            if(res.member !== null && res.member.reimburseClubId !== null){
                retrieveClubDetail(res.member.reimburseClubId);
            }

            let operatorItems= [], memberItems = [];
            for (var i = 0; i < res['clubs'].length; i++) {
                //Check Operator or Member
                if(res['clubs'][i].status === '운영자'){
                    operatorItems.push({
                        id: res['clubs'][i].clubID,
                        name: res['clubs'][i].name,
                        description: res['clubs'][i].description,
                        clubStatus: res['clubs'][i].clubStatus
                    });
                }else if(res['clubs'][i].status === '회원'){
                    memberItems.push({
                        id: res['clubs'][i].clubID,
                        name: res['clubs'][i].name,
                        description: res['clubs'][i].description,
                        clubStatus: res['clubs'][i].clubStatus
                    });
                }
            }
            setOperatorItems(operatorItems);
            setMemberItems(memberItems);
            setLoading(false)
        }).catch(err => {
            setAlertType('error');
            if (err.response.data.type === "biz") {
                setAlertMessage(err.response.data.message);
            } else {
                setAlertMessage("서비스 사용에 오류가 발생했습니다. 관리자에게 문의해주세요.");
            }
            setAlertVisible(true);
            setTimeout(() => { setAlertVisible(false) }, 5000);
            setLoading(false)
        })
    }

    const retrieveClubDetail = (clubID) => {
        setLoading(true)
        APIService.get('kcms', '/clubs/'+clubID)
            .then(res => {
                setMainClub(res.clubInfo);
                setNumOfMainClubMembers(res.members.length);
                setNumOfReimburseMembers(0);
                let cnt = 0;
                res.members.forEach((member) => {
                    if("Y" === member.reimburseYN) {
                        cnt++;
                    }
                })
                setNumOfReimburseMembers(cnt);
                setLoading(false)
            })
            .catch(err => {
                setAlertType('error');
                if (err.response.data.type === "biz") {
                    setAlertMessage(err.response.data.message);
                } else {
                    setAlertMessage("서비스 사용에 오류가 발생했습니다. 관리자에게 문의해주세요.");
                }
                setAlertVisible(true);
                setTimeout(() => { setAlertVisible(false) }, 5000); //1 Second delay
                setLoading(false)
            })
    }

    const OperatorTableHeader = ({ counter }) => {
        return (
            <Header
                children="내가 운영자인 동호회"
                variant="h2"
                counter={`(${counter})`}
                actions={
                    <SpaceBetween direction="horizontal" size="s">
                    </SpaceBetween>
                }
            />
        );
    };
    const MemberTableHeader = ({ counter }) => {
        return (
            <Header
                children="내가 회원인 동호회"
                variant="h2"
                counter={`(${counter})`}
                actions={
                    <SpaceBetween direction="horizontal" size="s">
                    </SpaceBetween>
                }
            />
        );
    };

    return (
        <Grid
            gridDefinition={[{ colspan: 12 },{ colspan: 6 }, { colspan: 6 }]} // Colspan is the number of columns (out of 12)
        >
            <div>
                <Alert type='info' visible={clubInfoVisible}>{clubInfoMessage}</Alert>
                <Container header={<Header variant="h2">비용지원 동호회 상세 정보</Header>}>
                    <ColumnLayout columns={3} borders="vertical">
                        <div>
                            <Box>동호회 명: {mainClub.name}</Box>
                        </div>
                        <div>
                            <Box>상태: {mainClub.status}</Box>
                        </div>
                        <div>
                            <Box>생성일: {mainClub.createdAt}</Box>
                        </div>
                    </ColumnLayout>
                    <ColumnLayout columns={3} borders="vertical">
                        <div>
                            <Box>가입 멤버 수: {numOfMainClubMembers}</Box>
                        </div>
                        <div>
                            <Box>운영비 지원 멤버 수: {numOfReimburseMembers}</Box>
                        </div>
                        <div>
                            {/* <Box>운영자: <Link target='_blank' href={`https://phonetool.amazon.com/users/${clubDetail.operatorAlias}`}><p>{clubDetail.operatorName}({clubDetail.operatorAlias}@)</p></Link></Box> */}
                            <Box>대표 운영자: {mainClub.operatorName}({mainClub.operatorAlias}@)</Box>
                        </div>
                    </ColumnLayout>
                    <ColumnLayout columns={1} borders="vertical">
                        <div>
                            <Box><p><b>동호회 소개: </b>{mainClub.description}</p></Box>
                        </div>
                    </ColumnLayout>
                    <ColumnLayout columns={1} borders="vertical">
                        <div>
                            <Box><p><b>동호회 상세 소개</b></p></Box>
                            <MDEditor.Markdown source={mainClub.markdownDescription} style={{ whiteSpace: 'pre-wrap' }} />
                        </div>
                    </ColumnLayout>
                </Container>
            </div>

            <div>
                <Table
                    header={<OperatorTableHeader counter={operatorItems.length} />}
                    variant="container"
                    columnDefinitions={[
                        {
                            header: "동호회 명",
                            id: "name",
                            minWidth: 150,
                            cell: (operatorItems) => {
                                return <Link href={`/clubs/${operatorItems.id}`}><p>{operatorItems.name}</p></Link>;
                            }
                        },
                        {
                            header: "동호회 소개",
                            id: "description",
                            minWidth: 300,
                            cell: (operatorItems) => {
                                return <p>{operatorItems.description}</p>;
                            }
                        },
                        {
                            header: "동호회 상태",
                            id: "status",
                            minWidth: 150,
                            cell: (memberItems) => {
                                return <p>{
                                    clubStatusCode.map((n) => {
                                        if(n.code === memberItems.clubStatus){
                                            return `${n.status}`;
                                        }
                                    })
                                }</p>;
                            }
                        }
                    ]}
                    wrapLines={true}
                    items={operatorItems}
                    loading={loading}
                    loadingText='Searching...'
                />
            </div>

            <div>
                <Table
                    header={<MemberTableHeader counter={memberItems.length} />}
                    variant="container"
                    columnDefinitions={[
                        {
                            header: "동호회 명",
                            id: "name",
                            minWidth: 150,
                            cell: (memberItems) => {
                                return <Link href={`/clubs/${memberItems.id}`}><p>{memberItems.name}</p></Link>;
                            }
                        },
                        {
                            header: "동호회 소개",
                            id: "description",
                            minWidth: 150,
                            cell: (memberItems) => {
                                return <p>{memberItems.description}</p>;
                            }
                        },
                        {
                            header: "동호회 상태",
                            id: "status",
                            minWidth: 150,
                            cell: (memberItems) => {
                                return <p>{
                                    clubStatusCode.map((n) => {
                                        if(n.code === memberItems.clubStatus){
                                            return `${n.status}`;
                                        }
                                    })
                                }</p>;
                            }
                        }
                    ]}
                    wrapLines={true}
                    items={memberItems}
                    loading={loading}
                    loadingText='Searching...'
                />
            </div>
            <Alert type={alertType} visible={alertVisible}>{alertMessage}</Alert>
        </Grid>
    );
}


import AppLayout from "@awsui/components-react/app-layout";
import SideNavigation from "@awsui/components-react/side-navigation";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Club, { ClubCreation, ClubCreationHistory, ClubUpdate } from './Club';
import ClubDetail from './ClubDetail';
import MyClub, { MyHistory } from './MyClub'
import NotFound from './NotFound';
import Main from './Main';

export default function Layout() {
    return (
        <AppLayout
            navigation={
                <SideNavigation
                    header={{
                        text: "Korea Club Management System",
                        href: "/"
                    }
                    }
                    items={
                        [
                            {
                                text: "Dashboard",
                                type: "link",
                                href: "/"
                            },
                            {
                                text: "동호회 리스트",
                                type: "link",
                                href: "/clubs"
                            },
                            {
                                type: "divider"
                            },
                            {
                                text: "내 동호회 리스트(비용지원 변경, 탈퇴)",
                                type: "link",
                                href: "/myclubs"
                            },
                            {
                                text: "내 동호회 변경내역",
                                type: "link",
                                href: "/myclubs/history"
                            },
                            /*{
                                text: "내 동호회",
                                type: "expandable-link-group",
                                href: "/myclubs",
                                items: [
                                    {
                                        text: "가입 목록",
                                        type: "link",
                                        href: "/myclubs"
                                    },
                                    {
                                        text: "변경 내역",
                                        type: "link",
                                        href: "/myclubs/history"
                                    }
                                ]
                            },*/
                            {
                                type: "divider"
                            },
                            {
                                text: "동호회 생성",
                                type: "link",
                                href: "/clubs/create"
                            },
                            // {
                            //     text: "동호회 생성 내역",
                            //     type: "link",
                            //     href: "/clubs/creationhistory"
                            // },
                            /*{
                                text: "동호회 생성",
                                type: "expandable-link-group",
                                href: "/clubs/create",
                                items: [
                                    {
                                        text: "생성 요청",
                                        type: "link",
                                        href: "/clubs/create"
                                    },
                                    {
                                        text: "생성 내역",
                                        type: "link",
                                        href: "/clubs/creationhistory"
                                    }
                                ]
                            },*/
                            {
                                type: "divider"
                            },
                            {
                                text: "시스템 사용 가이드",
                                type: "link",
                                external: true,
                                href: "https://w.amazon.com/bin/view/Moim/UserGuide/"
                            },
                            {
                                text: "비용 청구 가이드",
                                type: "link",
                                external: true,
                                href: "https://w.amazon.com/bin/view/AWS_Korea_Employee_Club/Expense_Guideline/"
                            },
                            {
                                text: "Bug 수정 및  기능개선 요청",
                                type: "link",
                                external: true,
                                href: "https://quip-amazon.com/bACRAEc6wagT/Moim-bug-"
                            },
                        ]}
                />
            }
            // breadcrumbs={
            //   <BreadcrumbGroup
            //     items={[
            //       {
            //         href: "/",
            //         text: "Home"
            //       },
            //       {
            //         href: "/settings",
            //         text: "Settings"
            //       }
            //     ]}
            //   />
            // }
            // notifications={<Flashbar items={flashs} />}
            content={
                <BrowserRouter>
                    <Routes>
                        <Route path='/' element={<Main />} />
                        <Route path='/index.html' element={<Main />} />
                        <Route path='/clubs' element={<Club />} />
                        <Route path='/clubs/*' element={<ClubDetail />} />
                        <Route path='/clubs/create' element={<ClubCreation />} />
                        <Route path='/clubs/creationhistory' element={<ClubCreationHistory />} />
                        <Route path='/clubs/update/*' element={<ClubUpdate />} />
                        <Route path='/myclubs' element={<MyClub />} />
                        <Route path='/myclubs/history' element={<MyHistory />} />
                        <Route path='/*' element={<NotFound />} />
                    </Routes>
                </BrowserRouter>
            }
            toolsHide={true}
        />
    );
}
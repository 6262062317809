export function removeOne(array, item) {
    const index = array.indexOf(item);
    if (index > -1) {
        array.splice(index, 1); // 2nd parameter means remove one item only
    }
    return array
}

export function removeAll(array, item) {
    let ax = -1;
    while ((ax = array.indexOf(item)) !== -1) {
        array.splice(ax, 1);
    }
    return array
}
import { useNavigate } from "react-router-dom";

import Container from "@awsui/components-react/container";
import Header from "@awsui/components-react/header";
import SpaceBetween from "@awsui/components-react/space-between";
import Button from "@awsui/components-react/button";
import ColumnLayout from "@awsui/components-react/column-layout";

export default function NotFound() {
    const navigate = useNavigate()
    return (
      <Container
        header={<Header variant={"h1"}>Not found</Header>}
        footer={
          <SpaceBetween direction="horizontal" size="s">
              <Button variant="primary" onClick={() => navigate(-1)}>뒤로가기</Button>
          </SpaceBetween>
        }
      >
        <ColumnLayout>
          <p>This page is not found</p>
        </ColumnLayout>
      </Container>
    );
}

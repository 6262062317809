const amplifyConfig = {
    "aws_cloud_logic_custom": [{
            "name": "kcms",
            "endpoint": process.env.REACT_APP_BACKEND_ENDPOINT,
        }
    ],
    "aws_project_region": "ap-northeast-2",
    "aws_cognito_region": "ap-northeast-2",
    "aws_user_pools_id": "ap-northeast-2_EcbOKsZTA",
    "aws_user_pools_web_client_id": "157f2lvjcl7kn7fg0nddu1cdcb",
    "oauth": {
        "domain": "kcms.auth.ap-northeast-2.amazoncognito.com",
        scope: ["openid"],
        redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN,
        redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT,
        responseType: "code",
    },
    federationTarget: "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": []
};

export default amplifyConfig;
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import Container from "@awsui/components-react/container";
import Header from "@awsui/components-react/header";
import SpaceBetween from "@awsui/components-react/space-between";
import Box from "@awsui/components-react/box";
import ColumnLayout from "@awsui/components-react/column-layout";
import Button from "@awsui/components-react/button";
import CollectionPreferences from "@awsui/components-react/collection-preferences";
import Pagination from "@awsui/components-react/pagination";
import Table from "@awsui/components-react/table";
import Tabs from '@awsui/components-react/tabs';
import Alert from "@awsui/components-react/alert";
import Modal from "@awsui/components-react/modal";
import { ButtonDropdown, Link, TextFilter } from "@awsui/components-react";
import { useCollection } from '@awsui/collection-hooks';
import MDEditor from '@uiw/react-md-editor';

import { getAlias } from './util/AliasUtil';
import APIService from './service/APIService';
import { CSVLink } from "react-csv";

import { isEmpty } from './util/ValidationUtil';

export default function ClubDetail() {
    const [clubDetail, setClubDetail] = useState({})
    const [clubMembers, setClubMembers] = useState([])
    const [reimburseMembers, setReimburseMembers] = useState([])
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [loading, setLoading] = useState(false)
    const [alertType, setAlertType] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [alertVisible, setAlertVisible] = useState(false)
    const [showJoinConfirm, setShowJoinConfirm] = useState(false)
    const [showQuitConfirm, setShowQuitConfirm] = useState(false)
    const [showQuitSelf, setShowQuitSelf] = useState(false)
    const [showOperatorConfirm, setShowOperatorConfirm] = useState(false)
    const [showMemberConfirm, setShowMemberConfirm] = useState(false)
    const [selectedItems, setSelectedItems] = useState([])
    const [isOperator, setIsOperator] = useState(false)
    const [isMember, setIsMember] = useState(false)
    const [activeTabId, setActiveTabId] = useState('clubMembersTable')

    const [quarters, setQuarters] = useState([])
    const [selectedQuarter, setSelectedQuarter] = useState([])

    const location = useLocation()
    const navigate = useNavigate();

    useEffect(() => {
        retrieveClubDetail();
        // getQuarters();
    }, []);

    useEffect(() => {
        if (selectedQuarter.length !== 0) {
            getReimburseMemberList(selectedQuarter);
        };
    }, [selectedQuarter]);

    const retrieveClubDetail = ()  => {
        setLoading(true)
        APIService.get('kcms', location.pathname)
            .then(res => {
                renderResult(res)
            })
            .catch(err => {
                setAlertType('error');
                if (err.response.data.type === "biz") {
                    setAlertMessage(err.response.data.message);
                } else {
                    setAlertMessage("서비스 사용에 오류가 발생했습니다. 관리자에게 문의해주세요.");
                }
                setAlertVisible(true);
                setTimeout(() => { setAlertVisible(false) }, 5000); //1 Second delay
                setLoading(false)
            })
    }

    const renderResult = res => {
        console.log(res)
        setIsMember(false);
        setIsOperator(false);
        setClubMembers(res.members);
        let reimburseMembers = []
        res.members.forEach((member) => {
            if("Y" === member.reimburseYN) {
                reimburseMembers.push(member)
            }
        })
        // setReimburseMembers(reimburseMembers)
        // getReimburseMemberList(selectedQuarter)
        let clubInfo = res.clubInfo
        clubInfo.numOfReimburseMembers = reimburseMembers.length

        clubInfo.numOfMembers = res.members.length
        // setClubDetail(res.clubInfo);
        setClubDetail(clubInfo);
        getQuarters();
        // setClubMembers(res.members);
        setLoading(false)
        getAlias().then(endUser => {
            for(let i=0; i<res.members.length; i++) {
                if(res.members[i].alias === endUser && res.members[i].status !== '운영자'){
                    setIsMember(true);
                    break;
                }else if(res.members[i].alias === endUser && res.members[i].status === '운영자') {
                    setIsOperator(true)
                    break
                }
            }
        })
    }

    const showJoinConfirmModal = () => {
        setShowJoinConfirm(true)
    }

    const hideJoinConfirmModal = () => {
        setShowJoinConfirm(false)
    }

    const updateClubInfo = () => {
        if(!isOperator) {
            setAlertType('error');
            setAlertMessage("운영자만 수정할 수 있습니다.");
            setAlertVisible(true);
            setTimeout(() => { setAlertVisible(false) }, 5000); //1 Second delay
        } else {
            let state = {
                "clubId" : clubDetail.clubId,
                "clubName" : clubDetail.name,
                "description" : clubDetail.description,
                "markdownDescription" : clubDetail.markdownDescription,
                "clubStatus" : clubDetail.status
            }
            navigate(`/clubs/update/${clubDetail.clubId}`, { state: state })
        }
    }

    const joinClub = () => {
        setShowJoinConfirm(false)
        setLoading(true)
        APIService.post('kcms', `/member/${clubDetail.clubId}`)
            .then(res => {
                // 등록이 성공하면 변경된 멤버 목록을 서버단에서 보내주도록 개발했다.
                // setClubMembers(res.members);
                // retrieveClubDetail();
                // setAlertType('info');
                // setAlertMessage("성공적으로 가입되었습니다. 비용지원 동호회 설정을 위해 내 동호회 리스트로 이동합니다. 단, 비용지원 동호회는 한 번 설정하면 분기 내에는 수정이 불가능합니다.");
                // setLoading(false)
                // setAlertVisible(true);
                // setTimeout(() => { setAlertVisible(false) }, 3000); //3 Second delay

                // 동호회 가입 시 자동으로 비용 지원 동호회 페이지로 이동한다.
                let state = {
                    "message" : `동호회(${clubDetail.name})에 성공적으로 가입되었습니다. 이곳에서 비용지원 동호회를 설정할 수 있습니다. 단, 비용지원 동호회는 한 번 설정하면 동일 분기 내에는 변경이 불가능합니다.`
                }
                navigate('/myclubs', { state: state })
            })
            .catch(err => {
                setAlertType('error');
                if (err.response.data.type === "biz") {
                    setAlertMessage(err.response.data.message);
                } else {
                    setAlertMessage("서비스 사용에 오류가 발생했습니다. 관리자에게 문의해주세요.");
                }
                setLoading(false)
                setAlertVisible(true);
                setTimeout(() => { setAlertVisible(false) }, 3000); //3 Second delay
            })
    }

    const copySelectedMembersEmailList = () => {
        let emailListStr = ''
        selectedItems.forEach((item) => {
            emailListStr = emailListStr + item.name + ' <' + item.alias + '@amazon.com>; '
        })
        navigator.clipboard.writeText(emailListStr) // copy text to clipboard
        setAlertType('info');
        setAlertMessage("복사되었습니다.");
        setAlertVisible(true);
        setTimeout(() => { setAlertVisible(false) }, 2000); //2 Second delay        
    }

    const copyAllMembersEmailList = () => {
        let emailListStr = ''
        clubMembers.forEach((member) => {
            emailListStr = emailListStr + member.name + ' <' + member.alias + '@amazon.com>; '
        })
        navigator.clipboard.writeText(emailListStr) // copy text to clipboard
        setAlertType('info');
        setAlertMessage("복사되었습니다.");
        setAlertVisible(true);
        setTimeout(() => { setAlertVisible(false) }, 2000); //2 Second delay
    }

    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        clubMembers,
        {
            pagination: { pageSize: itemsPerPage },
            sorting: {},
            filtering: {
                noMatch: (
                    <Box textAlign="center" color="inherit">
                        <b>No matches</b>
                        <Box color="inherit" margin={{ top: 'xxs', bottom: 's' }}>
                            키워드와 일치하는 data 가 없습니다.
                        </Box>
                        <Button onClick={() => actions.setFiltering('')}>키워드 제거</Button>
                    </Box>
                )
            }
        }
    );

    const { items:reimburseItems, actions:reimburseActions, filteredItemsCount:reimburseFilteredItemsCount, collectionProps:reimburseCollectionProps, filterProps:reimburseFilterProps, paginationProps:reimbursePaginationProps } = useCollection(
        reimburseMembers,
        {
            pagination: { pageSize: itemsPerPage },
            sorting: {},
            filtering: {
                noMatch: (
                    <Box textAlign="center" color="inherit">
                        <b>No matches</b>
                        <Box color="inherit" margin={{ top: 'xxs', bottom: 's' }}>
                            키워드와 일치하는 data 가 없습니다.
                        </Box>
                        <Button onClick={() => actions.setFiltering('')}>키워드 제거</Button>
                    </Box>
                )
            }
        }
    );

    const onSelectionChange = (e) => {
        setSelectedItems(e.detail.selectedItems)
    }

    const showQuitSelfModal = () => {
        if(isOperator) {
            setAlertType('error');
            setAlertMessage("운영자는 탈퇴를 할 수 없습니다.");
            setAlertVisible(true);
            setTimeout(() => { setAlertVisible(false) }, 5000); //1 Second delay
            return
        }
        setShowQuitSelf(true)
    }

    const hideQuitSelfModal = () => {
        setShowQuitSelf(false)
    }

    const quitSelfClub = () => {
        setShowQuitSelf(false)
        APIService.deleteWithQueryString('kcms', '/clubs', { IDs: clubDetail.clubId }).then(res => {
            if (res.statusCode === 200) {
                // setAlertType('success')
                // setAlertMessage('정상적으로 탈퇴되었습니다.')
                // setAlertVisible(true)
                // setTimeout(() => { setAlertVisible(false) }, 3000);
                // retrieveClubDetail()

                // 동호회 가입 시 자동으로 비용 지원 동호회 페이지로 이동한다.
                let state = {
                    "message" : `동호회(${clubDetail.name})에서 탈퇴되었습니다. 이곳에서 비용지원 동호회를 변경할 수 있습니다. 단, 비용지원 동호회는 한 번 설정하면 동일 분기 내에는 변경이 불가능합니다.`
                }
                navigate('/myclubs', { state: state })
            }
        }).catch(err => {
            setAlertType('error');
            if (err.response.data.type === "biz") {
                setAlertMessage(err.response.data.message);
            } else {
                setAlertMessage("서비스 사용에 오류가 발생했습니다. 관리자에게 문의해주세요.");
            }
            setAlertVisible(true);
            setTimeout(() => { setAlertVisible(false) }, 5000);
        })
    }

    const showQuitConfirmModal = () => {
        if (selectedItems.length === 0) {
            setAlertType('error')
            setAlertMessage('탈퇴처리할 대상을 선택해 주세요.')
            setAlertVisible(true)
            return
        }
        if(!isOperator) {
            setAlertType('error');
            setAlertMessage("운영자만 탈퇴 처리를 할 수 있습니다.");
            setAlertVisible(true);
            // setTimeout(() => { setAlertVisible(false) }, 5000); //1 Second delay
            return
        }
        // 운영자의 경우 탈퇴처리를 바로 해서 동호회 운영자가 없어지는 것을 방지하기 위해서 먼저 회원으로 변경을 유도한다.
        // 이 경우 운영자가 한 명이면 회원으로 변경이 안되기 때문에 동호회에서 운영자가 사라지는 것을 막을 수 있다.
        for(var i=0; i<selectedItems.length; i++) {
            // console.log(selectedItems[i].status)
            if(selectedItems[i].status === '운영자') {
                setAlertType('error')
                setAlertMessage('운영자는 바로 탈퇴처리 할 수 없습니다. 먼저 회원으로 변경해주세요.')
                setAlertVisible(true)
                return
            }
        }

        setShowQuitConfirm(true)
    }

    const hideQuitConfirmModal = () => {
        setShowQuitConfirm(false)
    }

    const quitClub = () => {
        setShowQuitConfirm(false)
        var aliases = '';
        for (var i = 0; i < selectedItems.length; i++) {
            aliases = aliases + selectedItems[i].alias
            if (i < selectedItems.length - 1) { // 마지막에는 쉼표를 붙이지 않기 위해서 구분
                aliases += ","
            }
        }
        
        APIService.deleteWithQueryString('kcms', `/clubs/${clubDetail.clubId}/members`, { aliases: aliases }).then(res => {
            // console.log(res.members)
            setAlertType('success')
            setAlertMessage('정상적으로 탈퇴되었습니다.')
            setAlertVisible(true)
            setTimeout(() => { setAlertVisible(false) }, 3000);
            setClubMembers(res.members);
        }).catch(err => {
            setAlertType('error');
            if (err.response.data.type === "biz") {
                setAlertMessage(err.response.data.message);
            } else {
                setAlertMessage("서비스 사용에 오류가 발생했습니다. 관리자에게 문의해주세요.");
            }
            setAlertVisible(true);
            setTimeout(() => { setAlertVisible(false) }, 5000);
        })
    }

    const showOperatorConfirmModal = () => {
        if (selectedItems.length === 0) {
            setAlertType('error')
            setAlertMessage('운영자로 변경할 대상을 선택해 주세요.')
            setAlertVisible('true')
            return
        }
        let isValid = true
        for(let i=0; i<selectedItems.length; i++) {
            if('운영자' === selectedItems[i].status) {
                setAlertType('error')
                setAlertMessage('선택한 회원에 이미 운영자가 있습니다.')
                setAlertVisible('true')
                isValid = false
                break
            }
        }
        if(!isValid) {
            return
        }
        if(!isOperator) {
            setAlertType('error');
            setAlertMessage("운영자만 이 작업을 수행할 수 있습니다.");
            setAlertVisible(true);
            setTimeout(() => { setAlertVisible(false) }, 5000); //1 Second delay
            return
        }
        setShowOperatorConfirm(true)
    }

    const hideOperatorConfirmModal = () => {
        setShowOperatorConfirm(false)
    }

    const showMemberConfirmModal = () => {
        if (selectedItems.length === 0) {
            setAlertType('error')
            setAlertMessage('일반 회원으로 변경할 대상을 선택해 주세요.')
            setAlertVisible('true')
            return
        }
        let isValid = true
        for(let i=0; i<selectedItems.length; i++) {
            if('회원' === selectedItems[i].status) {
                setAlertType('error')
                setAlertMessage('선택한 회원에 이미 일반 회원이 있습니다.')
                setAlertVisible('true')
                isValid = false
                break
            }
        }
        if(!isValid) {
            return
        }
        if(!isOperator) {
            setAlertType('error');
            setAlertMessage("운영자만 이 작업을 수행할 수 있습니다.");
            setAlertVisible(true);
            setTimeout(() => { setAlertVisible(false) }, 5000); //1 Second delay
            return
        }
        setShowMemberConfirm(true)
    }

    const hideMemberConfirmModal = () => {
        setShowMemberConfirm(false)
    }

    const changeMemberRole = (toRole) => {
        if(toRole === 'operator') {
            setShowOperatorConfirm(false)
        } else {
            setShowMemberConfirm(false)
        }
        var aliases = '';
        for (var i = 0; i < selectedItems.length; i++) {
            aliases = aliases + selectedItems[i].alias
            if (i < selectedItems.length - 1) { // 마지막에는 쉼표를 붙이지 않기 위해서 구분
                aliases += ","
            }
        }

        APIService.putWithQueryString('kcms', `/clubs/${clubDetail.clubId}/members`,
            { aliases: aliases, toRole: toRole })
        .then(res => {
            setSelectedItems([])
            setAlertType('success')
            setAlertMessage('정상적으로 변경되었습니다.')
            setAlertVisible(true)
            setTimeout(() => { setAlertVisible(false) }, 3000);
            renderResult(res)
        }).catch(err => {
            setAlertType('error');
            if (err.response.data.type === "biz") {
                setAlertMessage(err.response.data.message);
            } else {
                setAlertMessage("서비스 사용에 오류가 발생했습니다. 관리자에게 문의해주세요.");
            }
            setAlertVisible(true);
            setTimeout(() => { setAlertVisible(false) }, 5000);
        })
    }

    const clubMembersTable = <Table
        {...collectionProps}
        header={<Header children="동호회 멤버" variant="h2" counter={`(${clubMembers.length})`}
            actions={
                <SpaceBetween direction="horizontal" size="s">
                {
                    isOperator ?
                    <Button onClick={showQuitConfirmModal} variant="primary">탈퇴처리</Button>
                    :
                    <div/>
                }
                {
                    isOperator ?
                    <Button onClick={showOperatorConfirmModal} variant="primary">운영자로 변경</Button>
                    :
                    <div/>
                }
                {
                    isOperator ?
                    <Button onClick={showMemberConfirmModal} variant="primary">회원으로 변경</Button>
                    :
                    <div/>
                }
                    <Button onClick={copySelectedMembersEmailList} >선택 회원 메일 복사</Button>
                    <Button onClick={copyAllMembersEmailList} >전체 회원 메일 복사</Button>
                    <CSVLink data ={clubMembers} filename={`${clubDetail.name}-clubMembers.csv`}>
                        <Button
                            iconName="download"
                            disabled={clubMembers.length === 0}
                        >
				            {"Download"}
			            </Button>
		            </CSVLink>
                </SpaceBetween>
            }
        />}
        filter={
            <TextFilter
                {...filterProps}
                countText={`${filteredItemsCount} match(s)`}
                filteringPlaceholder={'검색 키워드'}
            />
        }
        variant="container"
        preferences={
            <CollectionPreferences
                title="Preferences"
                pageSizePreference={{
                    title: "Items per page",
                    options: [
                        { value: 10, label: "10" },
                        { value: 20, label: "20" },
                        { value: 50, label: "50" },
                        { value: 100, label: "100" }
                    ]
                }}
                preferences={{
                    pageSize: itemsPerPage,
                }}
                onConfirm={(e) => {
                    setItemsPerPage(e.detail.pageSize);
                }}
                confirmLabel="Confirm"
                cancelLabel="Cancel"
            />
        }
        pagination={<Pagination {...paginationProps} />}
        columnDefinitions={[
            {
                header: "이름(Alias)",
                id: "name",
                cell: (item) => {
                    // return <Link external={true} href={`https://phonetool.amazon.com/users/${item.alias}`}><p>{item.name}({item.alias}@)</p></Link>;
                    return <Link target='_blank' href={`https://phonetool.amazon.com/users/${item.alias}`}><p>{item.name}({item.alias}@)</p></Link>;
                },
                sortingField: "name"
            },
            {
                header: "가입일",
                id: "joinDate",
                cell: (item) => {
                    return <p>{item.joinedDate}</p>;
                },
                sortingField: "joinedDate"
            },
            {
                header: "역할",
                id: "status",
                cell: (item) => {
                    return <p>{item.status}</p>;
                },
                sortingField: "status"
            },
            {
                header: "비용지원 여부",
                id: "reimburseYN",
                cell: (item) => {
                    return <p>{item.reimburseYN}</p>;
                },
                sortingField: "reimburseYN"
            },
            {
                header: "CostCenter",
                id: "costcenter",
                cell: (item) => {
                    return <p>{item.costCenterId} ({item.costCenterName})</p>;
                },
                sortingField: "costcenter"
            }
        ]}
        items={items}
        loading={loading}
        loadingText='Searching...'
        selectionType='multi'
        onSelectionChange={onSelectionChange}
        selectedItems={selectedItems}
    />
    
    const getQuarters = () => {APIService.get('kcms', '/clubs/quarters')
            .then(res => {
                // console.log(res)
                const dropDownItems = res.map(item => {
                    return { id: item.dumpdate, text: item.dumpdate, variant: "primary" };
                });
                setQuarters(dropDownItems);
                // console.log(dropDownItems[0].text)
                setSelectedQuarter(dropDownItems[0].text);
                // getReimburseMemberList(dropDownItems[0].text);
                // getReimburseMemberList(dropDownItems[0].text)
            })
            .catch(err => {
                setAlertType('error');
                if (err.response.data.type === "biz") {
                    setAlertMessage(err.response.data.message);
                } else {
                    setAlertMessage("서비스 사용에 오류가 발생했습니다. 관리자에게 문의해주세요.");
                }
                setAlertVisible(true);
                setTimeout(() => { setAlertVisible(false) }, 5000); //1 Second delay
                setLoading(false);
            })}
    
    // const selectedQuarter = quarters[0] ? quarters[0].text : "Error"

    // const getQuarterMembers = () => {
    //     alert("Parameters?")
    // }

    const getReimburseMemberList = (dumpDate) => {
        var clubId = ''
        if(isEmpty(clubDetail)) {
            clubId = location.pathname.split('/')[2]
        } else {
            clubId = clubDetail.clubId
        }
        
        APIService.get('kcms', `/member/reimburse/${clubId}/${dumpDate}`)
        .then(res => {
            // console.log(res.members)
            // console.log(dumpDate)
            setSelectedQuarter(dumpDate);
            // setClubMembers(res.members);
            setReimburseMembers(res.members);
            // console.log(reimburseMembers)
            // setLoading(false)
        })
        .catch(err => {
            setAlertType('error');
            if (err.response.data.type === "biz") {
                setAlertMessage(err.response.data.message);
            } else {
                setAlertMessage("서비스 사용에 오류가 발생했습니다. 관리자에게 문의해주세요.");
            }
            setAlertVisible(true);
            setTimeout(() => { setAlertVisible(false) }, 5000); //1 Second delay
            setLoading(false);
    })}

    const clubReimburseMembersTable = <Table
        {...reimburseCollectionProps}
        header={<Header children="운영비 지원 멤버" variant="h2" counter={`(${reimburseMembers.length})`}
            actions={
                <SpaceBetween direction="horizontal" size="s">
                    <ButtonDropdown items={quarters} onItemClick={(e) => getReimburseMemberList(e.detail.id)}>{selectedQuarter}</ButtonDropdown>
                    <CSVLink data ={reimburseMembers} filename={`${clubDetail.name}-reimburseMembers-${selectedQuarter}.csv`}>
                        <Button
                            iconName="download"
                            disabled={clubMembers.length === 0}
                        >
				            {"Download"}
			            </Button>
		            </CSVLink>
                </SpaceBetween>
            }
        />}
        filter={
            <TextFilter
                {...reimburseFilterProps}
                countText={`${reimburseFilteredItemsCount} match(s)`}
                filteringPlaceholder={'검색 키워드'}
            />
        }
        variant="container"
        preferences={
            <CollectionPreferences
                title="Preferences"
                pageSizePreference={{
                    title: "Items per page",
                    options: [
                        { value: 10, label: "10" },
                        { value: 20, label: "20" },
                        { value: 50, label: "50" },
                        { value: 100, label: "100" }
                    ]
                }}
                preferences={{
                    pageSize: itemsPerPage,
                }}
                onConfirm={(e) => {
                    setItemsPerPage(e.detail.pageSize);
                }}
                confirmLabel="Confirm"
                cancelLabel="Cancel"
            />
        }
        pagination={<Pagination {...reimbursePaginationProps} />}
        columnDefinitions={[
            {
                header: "이름(Alias)",
                id: "name",
                cell: (item) => {
                    // return <Link external={true} href={`https://phonetool.amazon.com/users/${item.alias}`}><p>{item.name}({item.alias}@)</p></Link>;
                    return <Link target='_blank' href={`https://phonetool.amazon.com/users/${item.alias}`}><p>{item.name}({item.alias}@)</p></Link>;
                },
                sortingField: "name"
            },
            {
                header: "가입일",
                id: "joinDate",
                cell: (item) => {
                    return <p>{item.joinedDate}</p>;
                },
                sortingField: "joinedDate"
            },
            {
                header: "역할",
                id: "status",
                cell: (item) => {
                    return <p>{item.status}</p>;
                },
                sortingField: "status"
            },
            {
                header: "현재 비용지원 여부",
                id: "reimburseYN",
                cell: (item) => {
                    return <p>{item.reimburseYN}</p>;
                },
                sortingField: "reimburseYN"
            },
            {
                header: "CostCenter",
                id: "costcenter",
                cell: (item) => {
                    return <p>{item.costCenterId} ({item.costCenterName})</p>;
                },
                sortingField: "costcenter"
            }
        ]}
        // items={items}
        items={reimburseItems}
        // items={reimburseMembers}
        loading={loading}
        loadingText='Searching...'
        // selectionType='single'
        onSelectionChange={onSelectionChange}
        selectedItems={selectedItems}
    />

    const tabs = [
        {
            id: "clubMembersTable",
            label: "동호회 멤버",
            content: clubMembersTable
        },
        {
            id: "clubReimburseMembersTable",
            label: "운영비 지원 멤버",
            content: clubReimburseMembersTable
        }
    ]

    const handleTabChange = (e) => {
        setActiveTabId(e.detail.activeTabId)
    }

    return (
        <SpaceBetween size={"s"}>
            <SpaceBetween size={"s"}>
            <Container header={<Header variant="h2">동호회 상세 정보</Header>}
                footer={
                    <SpaceBetween direction="horizontal" size="s">
                    {
                        !isMember && !isOperator ?
                        <Button variant="primary" onClick={showJoinConfirmModal}
                                disabled={clubDetail.status==='폐쇄' || clubDetail.status==='운영중(모집X)'}>가입</Button>
                        :
                        <div/>
                    }
                    {
                        isMember ?
                        <Button onClick={showQuitSelfModal}>탈퇴</Button>
                        :
                        <div/>
                    }
                    {
                        isOperator ?
                        <Button onClick={updateClubInfo}>수정</Button>
                        :
                        <div/>
                    }
                    </SpaceBetween>
                }
            >
                <ColumnLayout columns={3} borders="vertical">
                    <div>
                        <Box>동호회 명: {clubDetail.name}</Box>
                    </div>
                    <div>
                        <Box>상태: {clubDetail.status}</Box>
                    </div>
                    <div>
                        <Box>생성일: {clubDetail.createdAt}</Box>
                    </div>
                </ColumnLayout>
                <ColumnLayout columns={3} borders="vertical">
                    <div>
                        {/* <Box>가입 멤버 수: {clubMembers.length}</Box> */}
                        <Box>가입 멤버 수: {clubDetail.numOfMembers}</Box>
                    </div>
                    <div>
                        <Box>운영비 지원 멤버 수: {clubDetail.numOfReimburseMembers}</Box>
                    </div>
                    <div>
                        {/* <Box>운영자: <Link target='_blank' href={`https://phonetool.amazon.com/users/${clubDetail.operatorAlias}`}><p>{clubDetail.operatorName}({clubDetail.operatorAlias}@)</p></Link></Box> */}
                        <Box>대표 운영자: {clubDetail.operatorName}({clubDetail.operatorAlias}@)</Box>
                    </div>
                </ColumnLayout>
                <ColumnLayout columns={1} borders="vertical">
                    <div>
                        <Box><p><b>동호회 소개: </b>{clubDetail.description}</p></Box>
                    </div>
                </ColumnLayout>
                <ColumnLayout columns={1} borders="vertical">
                    <div>
                        <Box><p><b>동호회 상세 소개</b></p></Box>
                        <MDEditor.Markdown source={clubDetail.markdownDescription} style={{ whiteSpace: 'pre-wrap' }} />
                    </div>
                </ColumnLayout>
            </Container>
            <Alert type={alertType} visible={alertVisible}>{alertMessage}</Alert>
            <Modal size='medium' header='Confirm' children={<p>동호회 "{clubDetail.name}"에 가입하시겠습니까?<br/>가입 후 "나의 동호회" page로 이동됩니다. 이곳에서 비용지원 동호회 지정을 확인하십시오.</p>}
                visible={showJoinConfirm} onDismiss={hideJoinConfirmModal}
                footer={
                    <SpaceBetween direction="horizontal" size="s">
                        <Button variant="primary" onClick={joinClub}>가입</Button>
                        <Button onClick={hideJoinConfirmModal}>취소</Button>
                    </SpaceBetween>
                }
            />
            <Modal size='medium' header='Confirm' children={<p>동호회 "{clubDetail.name}"에서 탈퇴하시겠습니까?<br/>탈퇴 후 "나의 동호회" page로 이동됩니다. 이곳에서 비용지원 동호회 지정을 확인하십시오.</p>}
                   visible={showQuitSelf} onDismiss={hideQuitSelfModal}
                   footer={
                       <SpaceBetween direction="horizontal" size="s">
                           <Button variant="primary" onClick={quitSelfClub}>탈퇴</Button>
                           <Button onClick={hideQuitSelfModal}>취소</Button>
                       </SpaceBetween>
                   }
            />
            <Modal size='medium' header='Confirm' children={`선택하신 회원을 탈퇴 처리 하시겠습니까?`}
                visible={showQuitConfirm} onDismiss={hideQuitConfirmModal}
                footer={
                    <SpaceBetween direction="horizontal" size="s">
                        <Button variant="primary" onClick={quitClub}>확인</Button>
                        <Button onClick={hideQuitConfirmModal}>취소</Button>
                    </SpaceBetween>
                }
            />
            <Modal size='medium' header='Confirm' children={`선택하신 회원을 운영자로 변경 하시겠습니까?`}
                visible={showOperatorConfirm} onDismiss={hideOperatorConfirmModal}
                footer={
                    <SpaceBetween direction="horizontal" size="s">
                        <Button variant="primary" onClick={e => changeMemberRole('operator')}>확인</Button>
                        <Button onClick={hideOperatorConfirmModal}>취소</Button>
                    </SpaceBetween>
                }
            />
            <Modal size='medium' header='Confirm' children={`선택하신 회원을 일반 회원으로 변경 하시겠습니까?`}
                visible={showMemberConfirm} onDismiss={hideMemberConfirmModal}
                footer={
                    <SpaceBetween direction="horizontal" size="s">
                        <Button variant="primary" onClick={e => changeMemberRole('member')}>확인</Button>
                        <Button onClick={hideMemberConfirmModal}>취소</Button>
                    </SpaceBetween>
                }
            />
            
            {/* {loading ? <Spinner size='big' variant='normal'></Spinner> : <></>} */}
            {/* </SpaceBetween>
            </Container> */}
        </SpaceBetween>
            <Tabs activeTabId={activeTabId} onChange={handleTabChange} tabs={tabs} />
        </SpaceBetween>
    )
}
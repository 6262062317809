import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import Container from "@awsui/components-react/container";
import Header from "@awsui/components-react/header";
import SpaceBetween from "@awsui/components-react/space-between";
import Button from "@awsui/components-react/button";
import Form from "@awsui/components-react/form";
import FormField from "@awsui/components-react/form-field";
import CollectionPreferences from "@awsui/components-react/collection-preferences";
import Pagination from "@awsui/components-react/pagination";
import Table from "@awsui/components-react/table";
import Alert from "@awsui/components-react/alert";
import Modal from "@awsui/components-react/modal";
import Spinner from "@awsui/components-react/spinner";
import { ButtonDropdown, Link, TextFilter, Input, Textarea, Box, Select } from "@awsui/components-react";
import { useCollection } from '@awsui/collection-hooks';
import MDEditor from '@uiw/react-md-editor';

import { isValidEmail } from './util/ValidationUtil';
import { removeOne } from './util/ArrayUtil';
import APIService from './service/APIService';

export default function Club() {
    const [clubName, setClubName] = useState('')
    const [clubs, setClubs] = useState([]);
    // const [start, setStart] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const [visibleFields, setVisibleFields] = useState(["clubName", "description", "category", "numOfMembers", "creator", "status"]);
    const [loading, setLoading] = useState(false)
    const [alertType, setAlertType] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [alertVisible, setAlertVisible] = useState(false)
    // const [checkedClubs, setCheckedClubs] = useState([]);
    // const navigate = useNavigate();

    useEffect(() => {
        retrieveClubList()
    }, [])

    const retrieveClubList = () => {
        setLoading(true)
        APIService.get('kcms', '/clubs', {
            clubName: clubName,
        })
            .then(res => {
                //console.log("result OK : " + JSON.stringify(res));
                // console.log(res);
                setClubs(res.clubInfoList);
                setLoading(false)
            })
            .catch(err => {
                setAlertType('error');
                if (err.response.data.type === "biz") {
                    setAlertMessage(err.response.data.message);
                } else {
                    setAlertMessage("서비스 사용에 오류가 발생했습니다. 관리자에게 문의해주세요.");
                }
                setAlertVisible(true);
                setTimeout(() => { setAlertVisible(false) }, 5000); //1 Second delay
                setLoading(false)
            })
    }

    // const handleKeyPress = (e) => {
    //     if (e.detail.key === "Enter") {
    //         retrieveClubList();
    //     }
    // }

    // const setChoosedClubs = (e) => {
    //     let array = []
    //     if (e.target.checked) {
    //         array.push(...checkedClubs, e.target.id)
    //     } else {
    //         array.push(...checkedClubs)
    //         removeOne(array, e.target.id)
    //     }
    //     setCheckedClubs(array)
    // }

    // const linkToClubUpdate = async () => {
    //     if (checkedClubs.length === 1) {
    //         //TODO : 이 작업을 수행하는 user 가 해당 동호회의 운영진이 맞는지 확인해서 아니라면 오류 처리 해야함.
    //         let link = `/clubs/update/${checkedClubs[0]}`
    //         // console.log(`club id ${checkedClubs[0]} 정보 조회~~`)
    //         let club = await retrieveClub(checkedClubs[0])
    //         // console.log(club)
    //         navigate(link, { state: club })
    //     }
    //     if (checkedClubs.length === 0) {
    //         setAlertMessage('수정할 클럽을 선택해주세요.')
    //     } else if (checkedClubs.length > 1) {
    //         setAlertMessage('수정할 클럽을 하나만 선택해주세요.')
    //     }
    //     setAlertType('error')
    //     setAlertVisible('true')
    // }

    // const retrieveClub = async (id) => {
    //     return await APIService.get('kcms', `/club/${id}`)
    // }

    const TableHeader = ({ counter }) => {
        return (
            <Header
                children="동호회 리스트"
                variant="h2"
                counter={`(${counter})`}
                actions={
                    <SpaceBetween direction="horizontal" size="s">
                        <Button onClick={() => retrieveClubList()} iconName="refresh" />
                        <Button href="/clubs/create" variant="primary">
                            신규 동호회 생성
                        </Button>
                    </SpaceBetween>
                }
            />
        );
    };

    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        clubs,
        {
            pagination: { pageSize: itemsPerPage },
            sorting: {},
            filtering: {
                noMatch: (
                    <Box textAlign="center" color="inherit">
                        <b>No matches</b>
                        <Box color="inherit" margin={{ top: 'xxs', bottom: 's' }}>
                            키워드와 일치하는 data 가 없습니다.
                        </Box>
                        <Button onClick={() => actions.setFiltering('')}>키워드 제거</Button>
                    </Box>
                )
            }
        }
    );

    return (
        // <Container header={<Header variant="h2">동호회 리스트</Header>}>
        //     <SpaceBetween size="s">
        //         <Form>
        //             <FormField label="동호회 명">
        //                 <SpaceBetween direction="horizontal" size="s">
        //                     <Input
        //                         value={clubName}
        //                         onChange={(e) => {
        //                             setClubName(e.detail.value);
        //                         }}
        //                         onKeyDown={handleKeyPress}
        //                     />
        //                     <Button variant="primary" onClick={retrieveClubList}>검색</Button>
        //                 </SpaceBetween>
        //             </FormField>
        //         </Form>
        <div>
            <Alert type={alertType} visible={alertVisible}>{alertMessage}</Alert>
            <Table
                {...collectionProps}
                header={<TableHeader counter={clubs.length} />}
                filter={
                    <TextFilter
                        {...filterProps}
                        countText={`${filteredItemsCount} match(s)`}
                        filteringPlaceholder={'검색 키워드'}
                    />
                }
                variant="container"
                // ariaLabels={{
                //     selectionGroupLabel: "Items selection",
                //     allItemsSelectionLabel: ({ selectedItems }) =>
                //         `${selectedItems.length} ${selectedItems.length === 1 ? "item" : "items"
                //         } selected`,
                //     itemSelectionLabel: ({ selectedItems }, item) => {
                //         let isItemSelected = selectedItems.filter((i) => i.clubName === item.clubName)
                //             .length;
                //         return `${item.name} is ${isItemSelected ? "" : "not"} selected`;
                //     }
                // }}
                preferences={
                    <CollectionPreferences
                        title="Preferences"
                        visibleContentPreference={{
                            title: "Fields",
                            options: [
                                {
                                    options: [
                                        { id: "clubName", label: "동호회 명", editable: false },
                                        { id: "description", label: "동호회 소개" },
                                        { id: "category", label: "분류" },
                                        { id: "numOfMembers", label: "회원수" },
                                        { id: "creator", label: "대표 운영자" },
                                        { id: "status", label: "상태" }
                                    ]
                                }
                            ]
                        }}
                        pageSizePreference={{
                            title: "Items per page",
                            options: [
                                { value: 10, label: "10" },
                                { value: 20, label: "20" },
                                { value: 50, label: "50" },
                                { value: 100, label: "100" }
                            ]
                        }}
                        preferences={{
                            pageSize: itemsPerPage,
                            visibleContent: visibleFields
                        }}
                        onConfirm={(e) => {
                            setItemsPerPage(e.detail.pageSize);
                            setVisibleFields(e.detail.visibleContent);
                        }}
                        confirmLabel="Confirm"
                        cancelLabel="Cancel"
                    />
                }
                pagination={<Pagination {...paginationProps} />}
                columnDefinitions={[
                    // {
                    //     header: "선택",
                    //     id: "check",
                    //     cell: (item) => {
                    //         return <input type="checkbox" id={item.id} name={item.id} onChange={setChoosedClubs}></input>;
                    //     }
                    // },
                    {
                        header: "동호회 명",
                        id: "clubName",
                        minWidth: 150,
                        cell: (item) => {
                            return <Link href={`/clubs/${item.clubId}`}><p>{item.name}</p></Link>;
                        },
                        sortingField: "name"
                    },
                    {
                        header: "동호회 소개",
                        id: "description",
                        minWidth: 300,
                        cell: (item) => {
                            return <p>{item.description}</p>;
                        }
                    },
                    {
                        header: "분류",
                        id: "category",
                        minWidth: 95,
                        cell: (item) => {
                            return <p>{item.category}</p>;
                        }
                    },
                    {
                        header: "회원수",
                        id: "numOfMembers",
                        minWidth: 95,
                        cell: (item) => {
                            return <p>{item.numOfMembers}</p>;
                        },
                        sortingField: "numOfMembers"
                    },
                    {
                        header: "대표 운영자",
                        id: "creator",
                        minWidth: 230,
                        cell: (item) => {
                            // return <Link external={true} href={`https://phonetool.amazon.com/users/${item.operatorAlias}`}><p>{item.operatorName}({item.operatorAlias}@)</p></Link>;
                            return <Link target='_blank' href={`https://phonetool.amazon.com/users/${item.operatorAlias}`}><p>{item.operatorName}({item.operatorAlias}@)</p></Link>;
                        }
                    },
                    {
                        header: "상태",
                        id: "status",
                        minWidth: 120,
                        cell: (item) => {
                            return <p>{item.status}</p>;
                        },
                        sortingField: "status"
                    }
                ]}
                visibleColumns={visibleFields}
                items={items}
                wrapLines={true}
                // resizableColumns={true}
                loading={loading}
                loadingText='Searching...'
            />
        </div>
        //     </SpaceBetween>
        // </Container>
    );
}

export const ClubCreation = () => {
    const [clubName, setClubName] = useState('')
    const [category, setCategory] = useState([{ id: "예술", text: "예술" }, { id: "학술", text: "학술" }, { id: "취미", text: "취미" }, { id: "운동", text: "운동" }, { id: "친목", text: "친목" }, { id: "NGO", text: "NGO" }, { id: "종합", text: "종합" }])
    const [description, setDescription] = useState('')
    const [markdownDescription, setMarkdownDescription] = useState('')
    const [ownerEmail, setOwnerEmail] = useState('')
    const [errorTextOfClubName, setErrorTextOfClubName] = useState('필수입력입니다.')
    const [errorTextOfDescription, setErrorTextOfDescription] = useState('필수입력입니다.')
    const [errorTextOfOwnerEmail, setErrorTextOfOwnerEmail] = useState('alias@amazon.com 형식이어야 합니다.')
    const [alertType, setAlertType] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [alertVisible, setAlertVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState('선택하세요')
    const navigate = useNavigate();
    const validate = (field, e) => {
        switch (field) {
            case 'clubName':
                // console.log(`clubName: ${e.detail.value}`)
                if (e.detail.value.trim() !== '') {
                    setErrorTextOfClubName('')
                } else {
                    setErrorTextOfClubName('동호회 명은 공백일 수 없습니다.')
                }
                break;
            case 'description':
                if (e.detail.value.trim() !== '') {
                    setErrorTextOfDescription('')
                } else {
                    setErrorTextOfDescription('동호회 소개는 공백일 수 없습니다.')
                }
                break;
            case 'ownerEmail':
                const email = e.detail.value.trim()
                if (isValidEmail(email) && email.endsWith('@amazon.com')) {
                    setErrorTextOfOwnerEmail('')
                } else {
                    setErrorTextOfOwnerEmail('alias@amazon.com 형식이어야 합니다.')
                }
                break;
            default:
                break;
        }
    };

    const handleSubmit = () => {
        if (clubName.trim() === '') {
            setErrorTextOfClubName('동호회 명은 공백일 수 없습니다.')
            return
        }
        if (description.trim() === '') {
            setErrorTextOfDescription('동호회 소개는 공백일 수 없습니다.')
            return
        }
        setLoading(true)
        APIService.post('kcms', '/clubs', {
            clubName: clubName,
            category: selectedCategory,
            description: description,
            markdownDescription: markdownDescription,
            ownerEmail: ownerEmail
        })
            .then(res => {
                // console.log("result OK : " + JSON.stringify(res));
                // setLoading(false)
                // setAlertType('success');
                // setAlertMessage('생성되었습니다. 동호회 상세 페이지로 이동합니다.');
                // setAlertVisible(true);

                // let link = `/clubs/${res.clubID}`
                // setTimeout(() => { setAlertVisible(false); navigate(link) }, 1000); //1 Second delay
                // 동호회 생성 시 자동으로 비용 지원 동호회 페이지로 이동한다.
                let state = {
                    "message" : `동호회(${clubName})가 성공적으로 생성되었습니다. 이곳에서 비용지원 동호회를 변경할 수 있습니다. 단, 비용지원 동호회는 한 번 설정하면 동일 분기 내에는 변경이 불가능합니다.`
                }
                navigate('/myclubs', { state: state })
            })
            .catch(err => {
                setLoading(false)
                setAlertType('error');
                if (err.response.data.type === "biz") {
                    setAlertMessage(err.response.data.message);
                } else {
                    setAlertMessage("서비스 사용에 오류가 발생했습니다. 관리자에게 문의해주세요.");
                }
                setAlertVisible(true);
                setTimeout(() => { setAlertVisible(false) }, 5000); //1 Second delay
            })
    }
    return (
        <Form
            actions={
                <SpaceBetween direction="horizontal" size="s">
                    <Button variant="primary" onClick={handleSubmit}>생성</Button>
                    <Button variant="normal" onClick={() => navigate(-1)}>취소</Button>
                </SpaceBetween>
            }>
            <Container header={<Header variant="h2">동호회 생성</Header>}>
                <SpaceBetween size="s">
                    <Alert type={alertType} visible={alertVisible}>{alertMessage}</Alert>
                    <FormField label="동호회 명" errorText={errorTextOfClubName}>
                        <Input name="clubName" value={clubName} onChange={(e) => { setClubName(e.detail.value); validate("clubName", e) }} />
                    </FormField>
                    <FormField label="동호회 분류" errorText={errorTextOfClubName}>
                        <ButtonDropdown items={category} onItemClick={(e) => setSelectedCategory(e.detail.id)}>{selectedCategory}</ButtonDropdown>
                        {/* <ButtonDropdown items={[{ id: "예술", text: "예술" }, { id: "학술", text: "학술" }]} ariaLabel="bbb" onItemClick={(e) => console.log(e)}>{selectedCategory}</ButtonDropdown> */}
                        {/* <Input name="category" value={category} onChange={(e) => { setCategory(e.detail.value); validate("category", e) }} /> */}
                    </FormField>
                    <FormField label="동호회 소개" errorText={errorTextOfDescription} description="이 곳에 동호회 소개 내용을 입력해주세요.">
                        <Textarea name="description" value={description} onChange={(e) => { setDescription(e.detail.value); validate("description", e) }}/>
                    </FormField>
                    <FormField label="동호회 상세소개" description="이 곳에 동호회 상세 소개 내용을 입력해주세요.">
                    </FormField>
                    <MDEditor
                        name="markdownDescription"
                        value={markdownDescription}
                        onChange={(e)=>{
                            setMarkdownDescription(e);
                        }}
                    />
                    {/* <FormField label="동호회 운영자" description="동호회 운영 대표자의 email 주소를 입력하세요." errorText={errorTextOfOwnerEmail}>
                        <Input name="ownerEmail" placeholder="alias@amazon.com" value={ownerEmail} onChange={(e) => { setOwnerEmail(e.detail.value.trim()); validate("ownerEmail", e) }} />
                    </FormField> */}
                    {loading ? <Spinner size='big' variant='normal'></Spinner> : <></>}
                </SpaceBetween>
            </Container>
        </Form>

    )
}

export const ClubCreationHistory = () => {
    const [clubName, setClubName] = useState('')
    const [clubs, setClubs] = useState([]);
    const [start, setStart] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [visibleFields, setVisibleFields] = useState(["check", "created_at", "name", "dscrptn", "creator", "status", "cnt"]);
    const [loading, setLoading] = useState(false)
    const [alertType, setAlertType] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [alertVisible, setAlertVisible] = useState(false)
    const [checkedClubs, setCheckedClubs] = useState([]);

    // const [items, setItems] = useState([]);
    const [checkedClubIDs, setCheckedClubIDs] = useState([]);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)

    useEffect(() => {
        retrieveClubList()
    }, [])


    const showDeleteConfirmModal = () => {
        if (checkedClubIDs.length === 0) {
            setAlertType('error')
            setAlertMessage('삭제할 동호회를 선택해 주세요.')
            setAlertVisible('true')
            return
        }
        setShowDeleteConfirm(true)
    }

    const hideDeleteConfirmModal = () => {
        setShowDeleteConfirm(false)
    }

    // const navigate = useNavigate();
    const retrieveClubList = () => {
        setLoading(true)
        APIService.get('kcms', '/clubs/creationhistory', {
            clubName: clubName,
        })
            .then(res => {
                //console.log("result OK : " + JSON.stringify(res));
                // console.log(res);
                // setClubs(res.clubs);
                // let items = [];
                // for (var i = 0; i < res.clubCreationHistoryList.length; i++) {
                //     items.push({
                //         id: res.clubCreationHistoryList[i].clubId,
                //         created_at: res.clubCreationHistoryList[i].created_at,
                //         name: res.clubCreationHistoryList[i].name,
                //         dscrptn: res.clubCreationHistoryList[i].dscrptn,
                //         creator: res.clubCreationHistoryList[i].creator,
                //         status: res.clubCreationHistoryList[i].status,
                //         cnt: res.clubCreationHistoryList[i].cnt
                //     });
                // }
                setClubs(res.clubCreationHistoryList);
                setLoading(false)
                // setClubs(items);
            })
            .catch(err => {
                setAlertType('error');
                if (err.response.data.type === "biz") {
                    setAlertMessage(err.response.data.message);
                } else {
                    setAlertMessage("서비스 사용에 오류가 발생했습니다. 관리자에게 문의해주세요.");
                }
                setAlertVisible(true);
                setTimeout(() => { setAlertVisible(false) }, 5000); //1 Second delay
                setLoading(false)
            })
        setLoading(false)
    }

    const deleteClubCreationHistory = () => {
        var ids = '';
        for (var i = 0; i < checkedClubIDs.length; i++) {
            ids = ids + checkedClubIDs[i] + ","
        }
        APIService.deleteWithQueryString('kcms', '/clubs/creationhistory', { IDs: ids }).then(res => {
            if (res.statusCode === 200) {
                var deletedIDSet = new Set();
                for (var i = 0; i < checkedClubIDs.length; i++) {
                    deletedIDSet.add(parseInt(checkedClubIDs[i]));
                }

                let newItems = [];
                for (var j = 0; j < items.length; j++) {
                    if (deletedIDSet.has(items[j].id) == false) {
                        newItems.push({
                            id: items[j].id, created_at: items[j].created_at,
                            name: items[j].name, dscrptn: items[j].dscrptn,
                            creator: items[j].creator, status: items[j].status, cnt: items[j].cnt
                        });
                    }
                }
                // setItems(newItems);
            }
        }).catch(err => {
            setAlertType('error');
            if (err.response.data.type === "biz") {
                setAlertMessage(err.response.data.message);
            } else {
                setAlertMessage("서비스 사용에 오류가 발생했습니다. 관리자에게 문의해주세요.");
            }
            setAlertVisible(true);
            setTimeout(() => { setAlertVisible(false) }, 5000);
        })
    }

    const handleKeyPress = (e) => {
        if (e.detail.key === "Enter") {
            retrieveClubList();
        }
    }

    const setChoosedClubs = (e) => {
        let array = []
        if (e.target.checked) {
            array.push(...checkedClubIDs, e.target.id)
        } else {
            array.push(...checkedClubIDs)
            removeOne(array, e.target.id)
        }
        setCheckedClubIDs(array)
    }

    const deleteClub = () => {
        setShowDeleteConfirm(false)
        if (checkedClubIDs.length === 0) {
            setAlertType('error')
            setAlertMessage('삭제할 동호회를 선택해 주세요.')
            setAlertVisible('true')
            return
        }
        var ids = '';
        for (var i = 0; i < checkedClubIDs.length; i++) {
            ids = ids + checkedClubIDs[i]
            if (i < checkedClubIDs.length - 1) { // 마지막에는 쉼표를 붙이지 않기 위해서 구분
                ids += ","
            }
        }

        APIService.deleteWithQueryString('kcms', '/clubs', { IDs: ids }).then(res => {
            if (res.statusCode === 200) {
                setAlertType('success')
                setAlertMessage('정상적으로 삭제되었습니다.')
                setAlertVisible(true)
                setTimeout(() => { setAlertVisible(false) }, 3000);
                setCheckedClubIDs([])
                retrieveClubList()
            }
        }).catch(err => {
            setAlertType('error');
            if (err.response.data.type === "biz") {
                setAlertMessage(err.response.data.message);
            } else {
                setAlertMessage("서비스 사용에 오류가 발생했습니다. 관리자에게 문의해주세요.");
            }
            setAlertVisible(true);
            setTimeout(() => { setAlertVisible(false) }, 5000);
        })
    }

    // const linkToClubUpdate = async () => {
    //     if (checkedClubIDs.length === 1) {
    //         //TODO : 이 작업을 수행하는 user 가 해당 동호회의 운영진이 맞는지 확인해서 아니라면 오류 처리 해야함.
    //         let link = `/clubs/update/${checkedClubIDs[0]}`
    //         // console.log(`club id ${checkedClubs[0]} 정보 조회~~`)
    //         let club = await retrieveClub(checkedClubIDs[0])
    //         // console.log(club)
    //         navigate(link, {state : club})
    //     }
    //     if (checkedClubIDs.length === 0) {
    //         setAlertMessage('삭제할 클럽을 선택해주세요.')
    //     } else if (checkedClubIDs.length > 1) {
    //         setAlertMessage('삭제할 클럽을 하나만 선택해주세요.')
    //     }
    //     setAlertType('error')
    //     setAlertVisible('true')
    // }

    // const retrieveClub = async (id) => {
    //     return await APIService.get('kcms', `/club/${id}`)
    // }

    const TableHeader = ({ counter }) => {
        return (
            <Header
                children="동호회 생성 내역"
                variant="h2"
                counter={`(${counter})`}
                actions={
                    <SpaceBetween direction="horizontal" size="s">
                        <Button onClick={() => retrieveClubList()} iconName="refresh" />
                        <Button onClick={() => showDeleteConfirmModal()} variant="primary">
                            삭제
                        </Button>
                    </SpaceBetween>
                }
            />
        );
    };

    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        clubs,
        {
            pagination: { pageSize: itemsPerPage },
            sorting: {},
            filtering: {
                noMatch: (
                    <Box textAlign="center" color="inherit">
                        <b>No matches</b>
                        <Box color="inherit" margin={{ top: 'xxs', bottom: 's' }}>
                            키워드와 일치하는 data 가 없습니다.
                        </Box>
                        <Button onClick={() => actions.setFiltering('')}>키워드 제거</Button>
                    </Box>
                )
            }
        }
    );

    return (
        // <Container header={<Header variant="h2">동호회 생성 내역</Header>}>
        //     <SpaceBetween size="s">
        //         <Form>
        //             <FormField label="동호회 명">
        //                 <SpaceBetween direction="horizontal" size="s">
        //                     <Input
        //                         value={clubName}
        //                         onChange={(e) => {
        //                             setClubName(e.detail.value);
        //                         }}
        //                         onKeyDown={handleKeyPress}
        //                     />
        //                     {/* <Button variant="primary" onClick={retrieveClubList}>검색</Button> */}
        //                     {/* <Button variant="primary" onClick={deleteClubCreationHistory}>삭제</Button> */}
        //                     <Button onClick={() => showDeleteConfirmModal()} variant="primary">삭제</Button>
        //                 </SpaceBetween>
        //             </FormField>
        //         </Form>
        <div>
            <Alert type={alertType} visible={alertVisible}>{alertMessage}</Alert>
            <Modal size='medium' header='Confirm' children={`동호회 ${checkedClubIDs.length}개를 삭제하시겠습니까?`}
                visible={showDeleteConfirm} onDismiss={hideDeleteConfirmModal}
                footer={
                    <SpaceBetween direction="horizontal" size="s">
                        <Button variant="primary" onClick={deleteClub}>확인</Button>
                        <Button onClick={hideDeleteConfirmModal}>취소</Button>
                    </SpaceBetween>
                }
            />
            <Table
                {...collectionProps}
                header={<TableHeader counter={clubs.length} />}
                filter={
                    <TextFilter
                        {...filterProps}
                        countText={`${filteredItemsCount} match(s)`}
                        filteringPlaceholder={'검색 키워드'}
                    />
                }
                variant="container"
                // ariaLabels={{
                //     selectionGroupLabel: "Items selection",
                //     allItemsSelectionLabel: ({ selectedItems }) =>
                //         `${selectedItems.length} ${selectedItems.length === 1 ? "item" : "items"
                //         } selected`,
                //     itemSelectionLabel: ({ selectedItems }, item) => {
                //         let isItemSelected = selectedItems.filter((i) => i.clubName === item.clubName)
                //             .length;
                //         return `${item.name} is ${isItemSelected ? "" : "not"} selected`;
                //     }
                // }}
                // preferences={
                //     <CollectionPreferences
                //         title="Preferences"
                //         visibleContentPreference={{
                //             title: "Fields",
                //             options: [
                //                 {
                //                     options: [
                //                         { id: "create_at", label: "생성날짜", editable: false },
                //                         { id: "name", label: "동호회 명", editable: false },
                //                         { id: "dscrptn", label: "동호회 소개" },
                //                         { id: "creator", label: "대표 운영자" },
                //                         { id: "status", label: "상태" },
                //                         { id: "cnt", label: "현재인원", editable: false },
                //                     ]
                //                 }
                //             ]
                //         }}
                //         pageSizePreference={{
                //             title: "Items per page",
                //             options: [
                //                 {
                //                     value: 10,
                //                     label: "10"
                //                 },
                //                 {
                //                     value: 20,
                //                     label: "20"
                //                 },
                //                 {
                //                     value: 50,
                //                     label: "50"
                //                 }
                //             ]
                //         }}
                //         preferences={{
                //             pageSize: itemsPerPage,
                //             visibleContent: visibleFields
                //         }}
                //         onConfirm={(e) => {
                //             setItemsPerPage(e.detail.pageSize);
                //             setVisibleFields(e.detail.visibleContent);
                //         }}
                //         confirmLabel="Confirm"
                //         cancelLabel="Cancel"
                //     />
                // }
                // pagination={
                //     <Pagination
                //         ariaLabels={{
                //             nextPageLabel: "Next page",
                //             previousPageLabel: "Previous page",
                //             pageLabel: (pageNumber) => `Page ${pageNumber}`
                //         }}
                //         currentPageIndex={start}
                //         pagesCount={clubs.length / itemsPerPage}
                //         onChange={(e) => {
                //             setStart(e.detail.currentPageIndex);
                //         }}
                //         onNextPageClick={(e) => {
                //             setStart(e.detail.requestedPageIndex);
                //         }}
                //         onPreviousPageClick={(e) => {
                //             setStart(e.detail.requestedPageIndex);
                //         }}
                //     />
                // }
                columnDefinitions={[
                    {
                        header: "선택",
                        id: "check",
                        minWidth: 100,
                        cell: (item) => {
                            return <input type="checkbox" id={item.clubId} name={item.name} onChange={setChoosedClubs}></input>;
                        }
                    },
                    {
                        header: "생성날짜",
                        id: "created_at",
                        minWidth: 150,
                        cell: (item) => {
                            return <p>{item.created_at}</p>;
                        }
                    },
                    {
                        header: "동호회 명",
                        id: "name",
                        minWidth: 150,
                        cell: (item) => {
                            return <Link href={`/clubs/${item.clubId}`}><p>{item.name}</p></Link>;
                        }
                    },
                    {
                        header: "동호회 소개",
                        id: "dscrptn",
                        minWidth: 300,
                        cell: (item) => {
                            return <p>{item.dscrptn}</p>;
                        }
                    },
                    {
                        header: "대표 운영자",
                        id: "creator",
                        minWidth: 150,
                        cell: (item) => {
                            return <p>{item.creator}</p>;
                        }
                    },
                    {
                        header: "상태",
                        id: "status",
                        minWidth: 100,
                        cell: (item) => {
                            return <p>{item.status}</p>;
                        }
                    },
                    {
                        header: "현재인원",
                        id: "cnt",
                        minWidth: 100,
                        cell: (item) => {
                            return <p>{item.cnt}</p>;
                        }
                    }
                ]}
                visibleColumns={visibleFields}
                // items={clubs.slice(
                //     (start - 1) * itemsPerPage,
                //     start * itemsPerPage
                // )}
                items={items}
                wrapLines={true}
                // resizableColumns={true}
                loading={loading}
                loadingText='Searching...'
            />
        </div>
        //     </SpaceBetween>
        // </Container>
    );
}

export const ClubUpdate = (props) => {
    const location = useLocation()
    const clubId = location.state.clubId
    const [clubName, setClubName] = useState(location.state.clubName)
    const [description, setDescription] = useState(location.state.description)
    const [markdownDescription, setMarkdownDescription] = useState(location.state.markdownDescription)
    const [clubStatus, setClubStatus] = useState('')
    const [clubStatusCode, setClubStatusCode] = useState([])

    // const [ownerEmail, setOwnerEmail] = useState(location.state.ownerEmail)
    const [showCloseConfirm, setShowCloseConfirm] = useState(false)
    const [errorTextOfClubName, setErrorTextOfClubName] = useState('')
    const [errorTextOfDescription, setErrorTextOfDescription] = useState('')
    const [errorTextOfOwnerEmail, setErrorTextOfOwnerEmail] = useState('')
    const [alertType, setAlertType] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [alertVisible, setAlertVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        getClubStatusCodeList();
    }, [])

    const validate = (field, e) => {
        switch (field) {
            case 'clubName':
                // console.log(`clubName: ${e.detail.value}`)
                if (e.detail.value.trim() !== '') {
                    setErrorTextOfClubName('')
                } else {
                    setErrorTextOfClubName('동호회 명은 공백일 수 없습니다.')
                }
                break;
            case 'ownerEmail':
                const email = e.detail.value.trim()
                if (isValidEmail(email) && email.endsWith('@amazon.com')) {
                    setErrorTextOfOwnerEmail('')
                } else {
                    setErrorTextOfOwnerEmail('alias@amazon.com 형식이어야 합니다.')
                }
                break;
            default:
                break;
        }
    }
    const getClubStatusCodeList = () => {
        APIService.get('kcms', '/clubs/statuslist', {})
            .then(res => {
                setClubStatusCode(res);
                res.map((n) => {
                    if (n.status === location.state.clubStatus) {
                        setClubStatus({
                            label: `${n.status}`,
                            value: `${n.code}`,
                            description: `${n.description}`,
                            markdownDescription: `${n.markdownDescription}`
                        });
                    }
                })
            })
            .catch(err => {
            })

    }

    const showCloseConfirmModal = () => {
        setShowCloseConfirm(true)
    }

    const hideCloseConfirmModal = () => {
        setShowCloseConfirm(false)
    }

    const closeClub = () => {
        setShowCloseConfirm(false)
        setLoading(true)

        APIService.put('kcms', `/clubs/close/${clubId}`, {
            clubStatusCode: clubStatus.value
        })
            .then(res => {
                // console.log("result OK : " + JSON.stringify(res));
                setLoading(false)
                setAlertType('success');
                setAlertMessage('폐쇄 하였습니다. 동호회 상세 페이지로 이동합니다.');
                setAlertVisible(true);
                let link = `/clubs/${clubId}`
                setTimeout(() => { setAlertVisible(false); navigate(link) }, 1000); //1 Second delay
            })
            .catch(err => {
                setLoading(false)
                setAlertType('error');
                setAlertMessage(err.response.data.message);
                setAlertVisible(true);
                setTimeout(() => { setAlertVisible(false) }, 5000); //1 Second delay
            })
    }

    const handleSubmit = () => {
        if (clubName.trim() === '') {
            setErrorTextOfClubName('동호회 명은 공백일 수 없습니다.')
            return
        }
        if (description.trim() === '') {
            setErrorTextOfDescription('동호회 소개는 공백일 수 없습니다.')
            return
        }
        if (clubStatus.label === '폐쇄') {
            showCloseConfirmModal()
            return
        }
        setLoading(true)
        APIService.put('kcms', `/clubs/${clubId}`, {
            clubName: clubName,
            description: description,
            markdownDescription : markdownDescription,
            clubStatusCode: clubStatus.value
        })
            .then(res => {
                // console.log("result OK : " + JSON.stringify(res));
                setLoading(false)
                setAlertType('success');
                setAlertMessage('수정되었습니다. 동호회 상세 페이지로 이동합니다.');
                setAlertVisible(true);
                let link = `/clubs/${clubId}`
                setTimeout(() => { setAlertVisible(false); navigate(link) }, 1000); //1 Second delay
            })
            .catch(err => {
                setLoading(false)
                setAlertType('error');
                setAlertMessage(err.response.data.message);
                setAlertVisible(true);
                setTimeout(() => { setAlertVisible(false) }, 5000); //1 Second delay
            })
    }
    return (
        <Form
            actions={
                <SpaceBetween direction="horizontal" size="s">
                    <Button variant="primary" onClick={handleSubmit}>수정</Button>
                    <Button variant="normal" onClick={() => navigate(-1)}>취소</Button>
                </SpaceBetween>
            }>
            <Container header={<Header variant="h2">동호회 수정</Header>}>
                <SpaceBetween size="s">
                    <Alert type={alertType} visible={alertVisible}>{alertMessage}</Alert>
                    <FormField label="동호회 명" errorText={errorTextOfClubName}>
                        <Input name="clubName" value={clubName} onChange={(e) => { setClubName(e.detail.value); validate("clubName", e) }} />
                    </FormField>
                    <FormField label="동호회 설명" errorText={errorTextOfDescription} description="이 곳에 동호회 소개 내용을 입력해주세요.">
                        <Textarea name="description" value={description} onChange={(e) => { setDescription(e.detail.value); validate("description", e) }} />
                    </FormField>

                    <FormField label="동호회 상세 설명" description="이 곳에 동호회 상세 소개 내용을 입력해주세요.">
                    </FormField>
                    <MDEditor
                        name="markdownDescription"
                        value={markdownDescription}
                        height={400}
                        onChange={(e) => {
                            setMarkdownDescription(e);
                            // validate("markdownDescription", e);
                        }}
                    />

                    <FormField label="동호회 상태" description="현재 동호회의 상태입니다.">
                        <Select
                            options={clubStatusCode.map((n) => {
                                return {
                                    label: `${n.status}`,
                                    value: `${n.code}`,
                                    description: `${n.description}`
                                };
                            })}
                            onChange={(e) => {
                                setClubStatus(e.detail.selectedOption);
                            }}
                            selectedOption={clubStatus}
                        />
                    </FormField>
                    {loading ? <Spinner size='big' variant='normal'></Spinner> : <></>}
                </SpaceBetween>
            </Container>
            <Modal size='medium' header='Confirm' children={`동호회를 폐쇄 하시겠습니까? 폐쇄처리를 하는 경우 가입된 모든 회원이 탈퇴 처리됩니다.`}
                   visible={showCloseConfirm} onDismiss={hideCloseConfirmModal}
                   footer={
                       <SpaceBetween direction="horizontal" size="s">
                           <Button variant="primary" onClick={closeClub}>확인</Button>
                           <Button onClick={hideCloseConfirmModal}>취소</Button>
                       </SpaceBetween>
                   }
            />
        </Form>

    )
}
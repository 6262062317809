import { useLocation } from "react-router-dom";
import APIService from './service/APIService';

import Alert from "@awsui/components-react/alert";
import Modal from "@awsui/components-react/modal";
import Button from "@awsui/components-react/button";
import Header from "@awsui/components-react/header";
import SpaceBetween from "@awsui/components-react/space-between";
import CollectionPreferences from "@awsui/components-react/collection-preferences";
import Pagination from "@awsui/components-react/pagination";
import Table from "@awsui/components-react/table";
import Box from "@awsui/components-react/box";
import Tabs from '@awsui/components-react/tabs';
import Link from "@awsui/components-react/link";
import TextFilter from "@awsui/components-react/text-filter";
import { useEffect, useState } from 'react';
import { removeOne } from './util/ArrayUtil';
import { useCollection } from '@awsui/collection-hooks';

export default function MyClub() {
    const [items, setItems] = useState([]);
    const [reimburseInfo, setReimburseInfo] = useState({})
    const [loading, setLoading] = useState(false)
    const [alertType, setAlertType] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [alertVisible, setAlertVisible] = useState(false)
    const [checkedClubIDs, setCheckedClubIDs] = useState([]);
    const [showReimburseConfirm, setShowReimburseConfirm] = useState(false)
    const [showQuitConfirm, setShowQuitConfirm] = useState(false)
    const location = useLocation()

    useEffect(() => {
        retrieveMyClubs(true)
    }, [])

    const retrieveMyClubs = (checkLocationState = false) => {
        setCheckedClubIDs([])
        setLoading(true)
        APIService.get('kcms', '/clubs', { joined: true }).then(res => {
            // console.log("result OK : " + JSON.stringify(res));
            let items = [];
            for (var i = 0; i < res['clubs'].length; i++) {
                items.push({
                    id: res['clubs'][i].clubID, name: res['clubs'][i].name,
                    description: res['clubs'][i].description, joinedAt: res['clubs'][i].joinedAt,
                    status: res['clubs'][i].status
                });
            }
            setItems(items);
            setReimburseInfo(res.member)
            setLoading(false)

            if(checkLocationState && location.state !== null) {
                setAlertType('info');
                setAlertMessage(location.state.message)
                setAlertVisible(true);
            }
        }).catch(err => {
            setAlertType('error');
            if (err.response.data.type === "biz") {
                setAlertMessage(err.response.data.message);
            } else {
                setAlertMessage("서비스 사용에 오류가 발생했습니다. 관리자에게 문의해주세요.");
            }
            setAlertVisible(true);
            setTimeout(() => { setAlertVisible(false) }, 5000);
            setLoading(false)
        })
    }

    const setChoosedClubs = (e) => {
        let array = []
        if (e.target.checked) {
            array.push(...checkedClubIDs, e.target.id)
        } else {
            array.push(...checkedClubIDs)
            removeOne(array, e.target.id)
        }
        setCheckedClubIDs(array)
    }

    const quitClub = () => {
        setShowQuitConfirm(false)
        if (checkedClubIDs.length === 0) {
            setAlertType('error')
            setAlertMessage('탈퇴할 동호회를 선택해 주세요.')
            setAlertVisible('true')
            return
        }
        var ids = '';
        for (var i = 0; i < checkedClubIDs.length; i++) {
            ids = ids + checkedClubIDs[i]
            if (i < checkedClubIDs.length - 1) { // 마지막에는 쉼표를 붙이지 않기 위해서 구분
                ids += ","
            }
        }

        APIService.deleteWithQueryString('kcms', '/clubs', { IDs: ids }).then(res => {
            if (res.statusCode === 200) {
                setAlertType('success')
                setAlertMessage('정상적으로 탈퇴되었습니다. 비용지원 동호회를 탈퇴했을 경우 다른 동호회를 비용지원 동호회로 지정해주세요.')
                setAlertVisible(true)
                // setTimeout(() => { setAlertVisible(false) }, 3000); // 계속 공지를 위해서 사라지지 않도록 변경
                setCheckedClubIDs([])
                retrieveMyClubs()
            }
        }).catch(err => {
            setAlertType('error');
            if (err.response.data.type === "biz") {
                setAlertMessage(err.response.data.message);
            } else {
                setAlertMessage("서비스 사용에 오류가 발생했습니다. 관리자에게 문의해주세요.");
            }
            setAlertVisible(true);
            setTimeout(() => { setAlertVisible(false) }, 5000);
        })
    }

    const changeReimburseClub = () => {
        setShowReimburseConfirm(false)
        if (checkedClubIDs.length !== 1) {
            setAlertType('error')
            setAlertMessage('비용 처리 동아리를 하나(만) 선택해주세요.')
            setAlertVisible('true')
            return
        }

        setAlertVisible('false')

        let path = `/clubs/reimburse/${checkedClubIDs[0]}`

        APIService.put('kcms', path, "{}").then(res => {
            if (res.statusCode === 200) {
                setAlertType('success')
                setAlertMessage('비용지원 동호회가 변경되었습니다.')
                setAlertVisible(true)
                setTimeout(() => { setAlertVisible(false) }, 3000);
                setCheckedClubIDs([])
                retrieveMyClubs()
            }
        }).catch(err => {
            setAlertType('error');
            if (err.response.data.type === "biz") {
                setAlertMessage(err.response.data.message);
            } else {
                setAlertMessage("서비스 사용에 오류가 발생했습니다. 관리자에게 문의해주세요.");
            }
            setAlertVisible(true);
            setTimeout(() => { setAlertVisible(false) }, 5000);
        })
    }

    const showReimburseConfirmModal = () => {
        if (checkedClubIDs.length !== 1) {
            setAlertType('error')
            setAlertMessage('비용 처리 동아리를 하나(만) 선택해주세요.')
            setAlertVisible('true')
            return
        }
        if (reimburseInfo.reimburseClubId == checkedClubIDs[0]) {
            setAlertType('error')
            setAlertMessage('현재 비용 지원을 받고 있는 동호회를 선택했습니다. 같은 동호회로 변경할 수 없습니다.')
            setAlertVisible('true')
            return
        }
        setShowReimburseConfirm(true)
    }

    const hideReimburseConfirmModal = () => {
        setShowReimburseConfirm(false)
    }

    const showQuitConfirmModal = () => {
        if (checkedClubIDs.length === 0) {
            setAlertType('error')
            setAlertMessage('탈퇴할 동호회를 선택해 주세요.')
            setAlertVisible('true')
            return
        }
        // 운영자의 경우 바로 탈퇴하지 못하고 회원으로 변경하도록 한다. 이는 운영자가 1명인 상황에서 탈퇴해서 해당 동호회에 운영자가 없어지는 경우를 방지하기 위해서이다.
        // checkedClubIDs 에 id 만 있으므로 items 에서 다시 비교해서 status 를 찾아야 한다.
        for (var i=0; i<checkedClubIDs.length; i++) {
            for (var j=0; j<items.length; j++) {
                // type 이 다르므로 type 비교를 하지 않기 위해서 == 로만 비교한다.
                if (checkedClubIDs[i] == items[j].id && items[j].status === '운영자') {
                    setAlertType('error')
                    setAlertMessage('운영자 역할인 동호회는 바로 탈퇴할 수 없습니다. 먼저 동호회 상세 화면에서 역할을 회원으로 변경해주세요.')
                    setAlertVisible('true')
                    return
                }
            }
        }
        setShowQuitConfirm(true)
    }

    const hideQuitConfirmModal = () => {
        setShowQuitConfirm(false)
    }

    const TableHeader = ({ counter }) => {
        return (
            <Header
                children="나의 동호회"
                variant="h2"
                counter={`(${counter})`}
                actions={
                    <SpaceBetween direction="horizontal" size="s">
                        <Button onClick={() => retrieveMyClubs()} iconName="refresh" />
                        <Button onClick={() => showReimburseConfirmModal()} variant="primary">비용지원 동호회 변경</Button>
                        <Button onClick={() => showQuitConfirmModal()}>탈퇴</Button>
                    </SpaceBetween>
                }
            />
        );
    };

    return (
        // <Container header={<Header variant="h2">My Club List</Header>}>
        //     <SpaceBetween size="m">
        //         <Form>
        //             <FormField>
        //                 <Button variant="normal" onClick={quitClub} >탈퇴</Button>
        //                 <Button variant="normal" onClick={changeReimburseClub} >비용 처리 동아리 변경</Button>
        //             </FormField>
        //         </Form>
        <div>
            <Alert type={alertType} visible={alertVisible}>{alertMessage}</Alert>
            <Modal size='medium' header='Confirm' children={`비용 지원 동호회 변경은 분기 별 1회만 가능합니다. 변경하시겠습니까?`}
                visible={showReimburseConfirm} onDismiss={hideReimburseConfirmModal}
                footer={
                    <SpaceBetween direction="horizontal" size="s">
                        <Button variant="primary" onClick={changeReimburseClub}>확인</Button>
                        <Button onClick={hideReimburseConfirmModal}>취소</Button>
                    </SpaceBetween>
                }
            />
            <Modal size='medium' header='Confirm' children={<p>동호회 {checkedClubIDs.length}개를 탈퇴하시겠습니까?<br/>탈퇴 후 비용지원 동호회 지정을 확인하십시오.</p>}
                visible={showQuitConfirm} onDismiss={hideQuitConfirmModal}
                footer={
                    <SpaceBetween direction="horizontal" size="s">
                        <Button variant="primary" onClick={quitClub}>확인</Button>
                        <Button onClick={hideQuitConfirmModal}>취소</Button>
                    </SpaceBetween>
                }
            />
            <Table
                header={<TableHeader counter={items.length} />}
                variant="container"
                columnDefinitions={[
                    {
                        header: "선택",
                        id: "check",
                        minWidth: 70,
                        width: 70,
                        maxWidth: 100,
                        cell: (item) => {
                            return <input type="checkbox" id={item.id} name={item.name} onChange={setChoosedClubs}></input>;
                        }
                    },
                    {
                        header: "동호회 명",
                        id: "name",
                        minWidth: 150,
                        cell: (item) => {
                            return <Link href={`/clubs/${item.id}`}><p>{item.name}</p></Link>;
                        }
                    },
                    {
                        header: "동호회 소개",
                        id: "description",
                        minWidth: 300,
                        cell: (item) => {
                            return <p>{item.description}</p>;
                        }
                    },
                    {
                        header: "가입일시",
                        id: "joinedAt",
                        minWidth: 200,
                        cell: (item) => {
                            return <p>{item.joinedAt}</p>;
                        }
                    },
                    {
                        header: "역할",
                        id: "status",
                        minWidth: 80,
                        cell: (item) => {
                            return <p>{item.status}</p>;
                        }
                    },
                    {
                        header: "비용지원 여부",
                        id: "reimburse",
                        minWidth: 120,
                        cell: (item) => {
                            return item.id === reimburseInfo.reimburseClubId ? <p>√</p> : <p />
                        }
                    }
                ]}
                wrapLines={true}
                items={items}
                loading={loading}
                loadingText='Searching...'
            />
        </div>
        //     </SpaceBetween>

        // </Container>
    );
}


export const MyHistory = () => {
    const [clubHistories, setClubHistories] = useState([]);
    const [reimburseClubHistories, setReimburseClubHistories] = useState([])
    const [loading, setLoading] = useState(false)
    const [alertType, setAlertType] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [alertVisible, setAlertVisible] = useState(false)
    const [itemsPerPageOfClubHistories, setItemsPerPageOfClubHistories] = useState(10)
    const [itemsPerPageOfReimburseHistories, setItemsPerPageOfReimburseHistories] = useState(10)
    const [activeTabId, setActiveTabId] = useState('clubHistories')

    useEffect(() => {
        retrieveMyHistory()
    }, [])

    const retrieveMyHistory = () => {
        setLoading(true)
        APIService.get('kcms', '/member/history').then(res => {
            // console.log("result OK : " + JSON.stringify(res));
            setClubHistories(res.clubHistories);
            setReimburseClubHistories(res.reimburseClubHistories)
            setLoading(false)
        }).catch(err => {
            setAlertType('error');
            if (err.response.data.type === "biz") {
                setAlertMessage(err.response.data.message);
            } else {
                setAlertMessage("서비스 사용에 오류가 발생했습니다. 관리자에게 문의해주세요.");
            }
            setAlertVisible(true);
            setTimeout(() => { setAlertVisible(false) }, 5000);
            setLoading(false)
        })
    }

    const clubHistoriesUseCollection = useCollection(
        clubHistories,
        {
            pagination: { pageSize: itemsPerPageOfClubHistories },
            sorting: {},
            filtering: {
                noMatch: (
                    <Box textAlign="center" color="inherit">
                        <b>No matches</b>
                        <Box color="inherit" margin={{ top: 'xxs', bottom: 's' }}>
                            키워드와 일치하는 data 가 없습니다.
                        </Box>
                        <Button onClick={() => clubHistoriesUseCollection.actions.setFiltering('')}>키워드 제거</Button>
                    </Box>
                )
            }
        }
    );

    const reimburseClubHistoriesUseCollection = useCollection(
        reimburseClubHistories,
        {
            pagination: { pageSize: itemsPerPageOfReimburseHistories },
            sorting: {},
            filtering: {
                noMatch: (
                    <Box textAlign="center" color="inherit">
                        <b>No matches</b>
                        <Box color="inherit" margin={{ top: 'xxs', bottom: 's' }}>
                            키워드와 일치하는 data 가 없습니다.
                        </Box>
                        <Button onClick={() => reimburseClubHistoriesUseCollection.actions.setFiltering('')}>키워드 제거</Button>
                    </Box>
                )
            }
        }
    );

    const clubHistoriesTable = <Table
        {...clubHistoriesUseCollection.collectionProps}
        header={<Header children="동호회 가입/탈퇴 이력" variant="h2" counter={`(${clubHistories.length})`} />}
        filter={
            <TextFilter
                {...clubHistoriesUseCollection.filterProps}
                countText={`${clubHistoriesUseCollection.filteredItemsCount} match(s)`}
                filteringPlaceholder={'검색 키워드'}
            />
        }
        variant="container"
        preferences={
            <CollectionPreferences
                title="Preferences"
                pageSizePreference={{
                    title: "Items per page",
                    options: [
                        { value: 10, label: "10" },
                        { value: 20, label: "20" },
                        { value: 50, label: "50" },
                        { value: 100, label: "100" }
                    ]
                }}
                preferences={{
                    pageSize: itemsPerPageOfClubHistories,
                }}
                onConfirm={(e) => {
                    setItemsPerPageOfClubHistories(e.detail.pageSize);
                }}
                confirmLabel="Confirm"
                cancelLabel="Cancel"
            />
        }
        pagination={<Pagination {...clubHistoriesUseCollection.paginationProps} />}
        columnDefinitions={[
            {
                header: "동호회 명",
                id: "name",
                cell: (item) => {
                    return <Link href={`/clubs/${item.clubId}`}><p>{item.clubName}</p></Link>;
                }
            },
            {
                header: "상태",
                id: "status",
                cell: (item) => {
                    return <p>{item.status}</p>;
                }
            },
            {
                header: "변경일시",
                id: "appliedDate",
                cell: (item) => {
                    return <p>{item.createdAt}</p>;
                }
            }
        ]}
        items={clubHistoriesUseCollection.items}
        loading={loading}
        loadingText='Searching...'
    />

    const reimburseClubHistoriesTable =
        <Table
            {...reimburseClubHistoriesUseCollection.collectionProps}
            header={<Header children="비용 지원 동호회 변경 이력" variant="h2" counter={`(${reimburseClubHistories.length})`} />}
            filter={
                <TextFilter
                    {...reimburseClubHistoriesUseCollection.filterProps}
                    countText={`${reimburseClubHistoriesUseCollection.filteredItemsCount} match(s)`}
                    filteringPlaceholder={'검색 키워드'}
                />
            }
            variant="container"
            preferences={
                <CollectionPreferences
                    title="Preferences"
                    pageSizePreference={{
                        title: "Items per page",
                        options: [
                            { value: 10, label: "10" },
                            { value: 20, label: "20" },
                            { value: 50, label: "50" },
                            { value: 100, label: "100" }
                        ]
                    }}
                    preferences={{
                        pageSize: itemsPerPageOfReimburseHistories,
                    }}
                    onConfirm={(e) => {
                        setItemsPerPageOfReimburseHistories(e.detail.pageSize);
                    }}
                    confirmLabel="Confirm"
                    cancelLabel="Cancel"
                />
            }
            pagination={<Pagination {...reimburseClubHistoriesUseCollection.paginationProps} />}
            columnDefinitions={[
                {
                    header: "동호회 명",
                    id: "name",
                    cell: (item) => {
                        return <Link href={`/clubs/${item.reimburseClubId}`}><p>{item.clubName}</p></Link>;
                    }
                },
                {
                    header: "변경일시",
                    id: "appliedDate",
                    cell: (item) => {
                        return <p>{item.createdAt}</p>;
                    }
                }
            ]}
            items={reimburseClubHistoriesUseCollection.items}
            loading={loading}
            loadingText='Searching...'
        />

    const tabs = [
        {
            id: "clubHistories",
            label: "동호회 가입/탈퇴 이력",
            content: clubHistoriesTable
        },
        {
            id: "reimburseClubHistories",
            label: "비용 지원 동호회 변경 이력",
            content: reimburseClubHistoriesTable
        }
    ]

    const handleTabChange = (e) => {
        setActiveTabId(e.detail.activeTabId)
    }

    return (
        <SpaceBetween size={"s"}>
            <Alert type={alertType} visible={alertVisible}>{alertMessage}</Alert>
            <Tabs activeTabId={activeTabId} onChange={handleTabChange} tabs={tabs} />
        </SpaceBetween>
    );
}
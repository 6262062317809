import React, { useState, useEffect } from 'react';

import Amplify, { Auth } from 'aws-amplify';
// import awsExports from './aws-exports';
import amplifyConfig from './config/amplifyConfig';

import { TopNavigation } from '@awsui/components-react';
import Spinner from "@awsui/components-react/spinner";
import "@awsui/global-styles/index.css";

import "./App.css";
import Layout from "./Layout";

// Amplify.configure(awsExports)
Amplify.configure(amplifyConfig)

export default function App() {
    const [user, setUser] = useState(null)
    const [alias, setAlias] = useState('')
    const i18nStrings = {
        overflowMenuTriggerText: "More",
        searchDismissIconAriaLabel: "Close search",
        searchIconAriaLabel: "Search"
    }
    const identity = {
        title: "Moim",
        href: "/",
        logo: {
            src: "/moim.png"
        }
    }
    const signIn = () => {
        Auth.federatedSignIn({
            provider: "AmazonFederate",
        })
    }
    useEffect(() => {
        if (user === null) {
            // console.log("authenticateUser 실행...")
            Auth.currentAuthenticatedUser() // Amplify 를 통해서 Cognito 에서 사용자 정보 조회
                .then(authUser => {
                    setAlias(authUser.username.split('_')[1])
                    setUser(authUser)
                })
                .catch(err => {
                    signIn(); // AmazonFederate 로 login 수행하면 다시 url 이 call back 으로 초기 화면으로 돌아오고 이 페이지가 다시 로딩 되면서 user === null 이라 Auth.currentAuthenticatedUser() 가 다시 실행된다. 그러나 이번에는 signIn 이 된 상태이므로 정보를 가져오므로 통과된다.
                })
        }
    }, []);
    if (user) {
        const utilities = [{text:`Hi, ${alias}@`, type:"button", disableUtilityCollapse:true}]
        return (
            <div id="b" className="pad-top bad-bot">
                <div
                    id="h"
                    style={{
                        position: "fixed",
                        top: 0,
                        height: "auto !important",
                        zIndex: 1003,
                        margin: 0,
                        padding: 0,
                        border: 0,
                        width: "100%"
                    }}
                >
                    <TopNavigation i18nStrings={i18nStrings} identity={identity} utilities={utilities}/>
                </div>
                <div id="Layout">
                    <Layout />
                </div>
            </div>
        );
    } else {
        return <Spinner size="large" />;
    }
}
